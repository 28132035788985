import React, { useState, useEffect } from "react";
import { Grid, Typography, useMediaQuery, Card, CardContent, TextField, MenuItem, } from "@mui/material";
import { Box } from "@mui/system";
import BackgroundWrinkles1 from "assets/BackgroundWrinkles1.svg";
import BackgroundWrinklesLight from "assets/backgroundWrinklesLight.svg";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from 'notistack';
import { useTheme } from "@emotion/react";
import { PAYABLE_AMOUNT } from "configs/constant";
import { useApolloMutation } from "hooks/useApolloQuery";
import { useCheckedLogin } from "hooks/useCheckedLogin";
import AlertDialog from "components/Alert/AlertDialog";
import { IoCart } from "react-icons/io5";
import { notifyAlertDeposit } from "slices/graphql.slice";
import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
import styles from "components/Auction/AuctionCardDetails/AuctionCard.module.css";

const PlisioDeposit = ({ darkMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isDeposit, setIsDeposit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedAmount, setCheckedAmount] = useState(0);
  const [txId, setTxId] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const loginedUser = useSelector((state) => state.auth.user);
  const { handleMutation } = useApolloMutation('plisioDeposit');
  dispatch(notifyAlertDeposit(loginedUser?.email));

  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, {variant});
  }
  useCheckedLogin();

  const handleClickCheckAmount = (e) => {
    const { value } = e.target;
    setCheckedAmount(value);
  }

  const handleClickDeposit = () => {
    if (!checkedAmount) {
      showSnackMessage('You must check available amount.', 'error');
      return;
    }
    setTxId(0);
    localStorage.setItem('txId', 0);
    setIsDeposit(true);
  }
  const handleAgreeDeposit = async () => {
    setIsDeposit(false);
    setIsLoading(true);
    const plisioResult = await handleMutation({
      amount: parseInt(checkedAmount),
    });

    if (plisioResult?.data?.plisioDeposit?.status !== 'success') {
      showSnackMessage(plisioResult?.data?.plisioDeposit?.message, 'error');
      return;
    }

    localStorage.setItem('txId', plisioResult?.data?.plisioDeposit?.tx_id);
    setTxId(plisioResult?.data?.plisioDeposit?.tx_id);
    window.open(plisioResult?.data?.plisioDeposit?.url, '_blank');
    setIsLoading(false);
  }

  return (
    <>
      <AlertDialog
        open={isDeposit}
        handleClose={() => setIsDeposit(false)}
        handleAgree={handleAgreeDeposit}
        darkMode={darkMode}
        title={'ALERT_TITLE'}
        content={'ASK_DEPOSIT'}
      />
      <Loader
        isLoading={ isLoading }
        message={"loading"}
        darkMode={darkMode}
      />
      <Box
        color={darkMode ? "#ffffff" : "#171c26"}
        sx={{
          position: "relative",
        }}
      >
        <Box
          style={{
            position: "fixed",
            height: "100vh",
          }}
        >
          <img
            src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
            alt="Wrinkles"
          />
        </Box>
        <Box sx={!isMobile ? { mt: 11 } : { mt: 2 }}>
          {!isMobile ? (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                gap: 4,
                alignItems: "center",
              }}
            >
              <Typography
                component="div"
                sx={{
                  borderBottom: `2px solid ${darkMode ? "#ffffff" : "#171c26"}`,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ zIndex: 2, cursor: "pointer" }}
                >
                  {t("DEPOSIT")}
                </Typography>
                {darkMode && (
                  <Typography
                    variant="h1"
                    component="p"
                    sx={{
                      background:
                        "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)",
                      borderRadius: "4px",
                      width: "35px",
                      height: "24px",
                      ml: -4,
                    }}
                  ></Typography>
                )}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "fixed",
                  top: "3%",
                  zIndex: "10000",
                  width: "75%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box pb={2} ml={6}>
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    component="div"
                    sx={{
                      borderBottom: `${
                        darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                      }`,
                    }}
                  >
                    {t("DEPOSIT")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{
              backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "95%",
              marginLeft: "auto",
              marginRight: "auto",
              mt: 3,
          }}>
            <Grid container>
              <Card sx={{ width: "100vw" }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={isMobile ? 12 : 6} direction="column">
                      <Box sx={{
                          width: "100%"
                        }}
                        mt={1}
                      >
                        <TextField
                          select
                          name="amount"
                          label={t("AMOUNT")}
                          fullWidth
                          required="required"
                          color='secondary'
                          onChange = {handleClickCheckAmount}
                          sx={{mt: 1}}
                          >
                          {PAYABLE_AMOUNT?.map((amount, index) => (
                            <MenuItem key={index} value={amount} alignItems="center">
                              <div>
                                <Typography 
                                  variant="body1"
                                  component="span"
                                  fontSize={"20px"}
                                  marginLeft={"10px"}
                                >${amount}</Typography>
                              </div>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box sx={{ textAlign: 'right', width: "100%" }} mb={3} mt={3} xs={12}>
                        <GradientButtonPrimary
                          className={styles.gradientButtonClass}
                          variant="contained"
                          onClick={() => handleClickDeposit()}
                          sx={{
                              marginTop: "1rem",
                              flexShrink: 1,
                          }}
                        >
                          <Typography
                              color="#ffffff"
                              component="span"
                              fontSize={20}
                              mt={0.5}
                          >
                              <IoCart />
                          </Typography>
                          <Typography variant="body1" component="span">
                            {t('DEPOSIT')}
                          </Typography>
                        </GradientButtonPrimary>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PlisioDeposit;
