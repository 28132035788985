import { Typography } from "@mui/material";
import { useState, useEffect } from 'react';
import { IoCart } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";
import { AuctionStatus } from "configs/constant";

export const OperatorBtnGroup = ({ auctionStatus, isSellStatus, handleSellClick,  handleCancelClick, handleLowPrice }) => {
    const { t } = useTranslation();
    const [sellStatus, setSellStatus] = useState(isSellStatus);
    useEffect(() => {
        setSellStatus(isSellStatus);
    },  [isSellStatus]);

    const handleOnCancel = () => {
        setSellStatus(true);
        if (handleCancelClick) {
            handleCancelClick();
        }
    }
    return (
        <>
            {auctionStatus === AuctionStatus.ON_AUCTION && !sellStatus ? (
                <>
                    <GradientButtonPrimary
                        className={styles.gradientButtonClass}
                        sx={{
                            marginRight: 10
                        }}
                        variant="contained"
                        onClick={handleOnCancel}
                    >
                        <Typography
                            color="#ffffff"
                            component="span"
                            fontSize={20}
                            mt={0.5}
                        >
                            <IoCart />
                        </Typography>
                        <Typography variant="body1" component="span">
                            {t("CANCEL_LISTING")}
                        </Typography>
                    </GradientButtonPrimary>
                    <GradientButtonPrimary
                        className={styles.gradientButtonClass}
                        variant="contained"
                        onClick={handleLowPrice}
                    >
                        <Typography
                            color="#ffffff"
                            component="span"
                            fontSize={20}
                            mt={0.5}
                        >
                            <IoCart />
                        </Typography>
                        <Typography variant="body1" component="span">
                            {t("LOW_PRICE")}
                        </Typography>
                    </GradientButtonPrimary>
                </>
            ) : (
                <GradientButtonPrimary
                    className={styles.gradientButtonClass}
                    variant="contained"
                    onClick={handleSellClick}
                >
                    <Typography
                        color="#ffffff"
                        component="span"
                        fontSize={20}
                        mt={0.5}
                    >
                        <IoCart />
                    </Typography>
                    <Typography variant="body1" component="span">
                        {t("SELL_NOW")}
                    </Typography>
                </GradientButtonPrimary>
            )}
        </>
    );
}