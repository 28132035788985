import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GraphQLClient } from "graphql-request";
import { graphqlConfigs, BID_ADDED_SUBSCRIPTION, AUCTION_ENDED_SUBSCRIPTION, ALERT_PAY_SUBSCRIPTION } from "./graphql.config";
import axios from 'axios';
import { SubscriptionClient } from "subscriptions-transport-ws"; 
import { backend_graphql_url, backend_graphql_subscription_url, MAINTENANCE_URL } from "configs/constant";

let url = 'nft/fetchNFTs';
let dataArea = 'data';
let client = null;
let currentQuery = null;
let currentVariables = {};

const requestWithToken = async (query, variables, token) => {
  currentQuery = query;
  currentVariables = variables;
  if (client === null) {
    client = new GraphQLClient(backend_graphql_url, {
      transport: axios,
    });
  }

  client.setHeader('authorization', `Bearer ${token}`);
  try {
    const response = await client.rawRequest(currentQuery, currentVariables);
    return response?.data;
  } catch (error) {
    // Handle any errors
    console.log(error);
    window.location.href =  MAINTENANCE_URL;
  }
};


export const setURL = (data, prefix = '') => {
  url = graphqlConfigs[data.name].url;
  dataArea = prefix ? `${prefix}_${data?.name}` : data?.name;
};

export const fetchGraphQL =  createAsyncThunk(url, async(data, { getState }) => {
  const token = getState().auth.accessToken;
  const query = graphqlConfigs[data.name].query;
  const response = await requestWithToken(query, data.params, token);
  return response;
});

export const subscribeGraphQL = createAsyncThunk('nft/subscribe', async(data, {getState}) => {
  return data;
});

export const currentAuctionEnded = createAsyncThunk('nft/auctionEnded', async(data, {getState}) => {
  return data;
})

export const alertPayNotify = createAsyncThunk('nft/alertPayNotify', async(data, {getState}) => {
  return data;
})

let subscriptionClient = null;
let subscription = null;
let auctionEndedSubscription = null;
let alertPaySubscription = null;

export const subscribeToBidAdded = (room) => (dispatch, getState) => {
  if (subscriptionClient === null) {
    subscriptionClient = new SubscriptionClient(backend_graphql_subscription_url, {
      reconnect: true
    });
  }
  if (subscription === null) {
    subscription = subscriptionClient.request({
      query: BID_ADDED_SUBSCRIPTION,
      variables: {room},
    });

    subscription.subscribe({
        next: (response) => {
          dispatch(subscribeGraphQL(response?.data?.bidAdded));
        },
    });
  }

  if (auctionEndedSubscription === null) {
    auctionEndedSubscription = subscriptionClient.request({
      query: AUCTION_ENDED_SUBSCRIPTION,
      variables: { room },
    });

    auctionEndedSubscription.subscribe({
      next: (response) => {
        dispatch(currentAuctionEnded(response.data.auctionEnded));
        subscription = null;
        auctionEndedSubscription = null;
        subscriptionClient.close();
        subscriptionClient = null;
        alertPaySubscription = null;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

 
};
export const notifyAlertDeposit = (email) => (dispatch, getState) => {
  if (subscriptionClient === null) {
    subscriptionClient = new SubscriptionClient(backend_graphql_subscription_url, {
      reconnect: true
    });
  }
  if (alertPaySubscription === null ) {
    // const email = localStorage.getItem('email')
    alertPaySubscription = subscriptionClient.request({
      query: ALERT_PAY_SUBSCRIPTION,
      variables: { email },
    });

    alertPaySubscription.subscribe({
      next: (response) => {
        dispatch(alertPayNotify(response?.data?.alertPay));
        subscription = null;
        auctionEndedSubscription = null;
        subscriptionClient.close();
        subscriptionClient = null;
        alertPaySubscription = null;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }
}
export const nftSlice = createSlice({
  name: 'nft',
  initialState: { data: null, loading: false, error: null, nft: {}, nfts: {}},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGraphQL.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGraphQL.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.meta?.arg?.params == currentVariables) {
          state[dataArea] = action.payload;
        }
      })
      .addCase(fetchGraphQL.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(subscribeGraphQL.fulfilled, (state, action) => {
        if (action.payload?.nft !== null && action.payload?.user !== null && action.payload?.amount !== null) {
          state.addedBid = action.payload;
        }
      })
      .addCase(currentAuctionEnded.fulfilled, (state, action) => {
        // const auction = action.payload;
        state.auctionFinishedInfo = action.payload;
        // const currentEmail = localStorage.getItem('email');
        // state.auction.nftcardFromTokenId.enableClaim = auction.auction_winner.email === currentEmail;
        // state.auction.nftcardFromTokenId.auction_status = auction.auction_status;
        // state.auction.nftcardFromTokenId.auction_winner = auction.auction_winner;
        // state.auction.nftcardFromTokenId.currentTime = auction.currentTime;
      })
      .addCase(alertPayNotify.fulfilled, (state, action) => {
        console.log(action.payload);
        state.alertPay = action.payload;
      });
  }
});

const { reducer } = nftSlice;
export const { reset } = nftSlice.actions;

export default reducer;
