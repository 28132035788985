export const constColors = {
  black: "#222222",
  white: "#FFFFFF",
  color100: "#6B547E",
  color200: "#213f97",
  color300: "#213f97",
  color400: "#FFFFFF",
  color500: "#A32B47",
  color600: "#EC9091",
  color700: "#FED1D1",
  color800: "#EA0000",
  color900: "#00EA25",
  redColor: "#EA0000",
  cardFooter: "#F9F9F9",
  borderColor: "#BFBFC0",
  filterContainerColor: "#FBFDFF",
  inputBorder: "#ECECEC",
  disabledColor: "#F8F8F8",
  descColor: "#707A83",
  transparent: "transparent",
};
