import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { apolloGraphqlConfigs } from "slices/apolloGraphql.config";
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { loginWithRefreshToken } from "slices/auth.slice";
import { MAINTENANCE_URL } from "configs/constant";

const useLoginWithRefreshToken = async () => {
    const accessToken = Cookies.get('access_token');
    const dispatch = useDispatch();
    if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        const refreshToken = Cookies.get('refresh_token');
        if (decodedToken?.exp <= Date.now() / 1000) {
            await dispatch(loginWithRefreshToken({refreshToken}));
        }
    }
}
export const useApolloQuery = (name, params, fetchCompleted) => {
    useLoginWithRefreshToken();

    const is_error = 0;
    const query = apolloGraphqlConfigs[name]?.query;
    const { loading, error, data, refetch } = useQuery(query, {
        variables: params ?? {},
        onCompleted: () => {
            if (fetchCompleted && typeof fetchCompleted === "function")
                fetchCompleted();
        }
    });

    if (error && is_error == 0) {
        window.location.href =  MAINTENANCE_URL;
        console.log('aaa');
    } else {

    }
    return { loading, error, data, refetch };
}

export const useApolloMutation = (name, handleCompleted) => {
    useLoginWithRefreshToken();
    const query = apolloGraphqlConfigs[name]?.query;

    const [ mutation, { data, loading, error } ] = useMutation(query, {
        onCompleted: () => {
            if (handleCompleted && typeof  handleCompleted === "function")
                handleCompleted();
        }
    });

    const handleMutation = async (params) => {
        return await mutation({
            variables: params ?? {}
        });
    }
    return { handleMutation, data, loading, error };
}

export const useApolloSubscription = (query, params, isAllow = true) => {
    const { data, loading, error  } = useSubscription(query, {
        variables: params,
    });
    return {data, loading, error};
}