import {
    Box
} from "@mui/system";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";
import { HistoryTabPanel } from "components/NftCard/NftCardDetails/Component/HistoryView/HistoryTabPanel";

export const HistoryView = ({ darkMode, tabValue, bids, history, isMobile, handleChange}) => {
    return(
        <>
            {isMobile ? (
            <Box width="90%" className={styles.tabBox}>
                <Box mt={5} mb={-3}>
                    <HistoryTabPanel
                        darkMode={darkMode}
                        tabValue={tabValue}
                        bids={bids}
                        history={history}
                        isMobile={isMobile}
                        handleChange={handleChange}
                    />
                </Box>
            </Box>
            ) : (
            <Box className={styles.tabBox}>
                <Box sx={{ width: "100%"}}>
                    <HistoryTabPanel
                        darkMode={darkMode}
                        tabValue={tabValue}
                        bids={bids}
                        history={history}
                        isMobile={isMobile}
                        handleChange={handleChange}
                    />
                </Box>
            </Box>
            )}
        </>
    );
}