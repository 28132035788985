import { Box } from "@mui/system";
import { useState, useEffect } from 'react';
import { Typography, Stack, IconButton } from "@mui/material";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { HiOutlineClock } from "react-icons/hi";
import { BsBookmark, BsFillBookmarkFill } from "react-icons/bs";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";

export const SetLikeBtnGroup = ({
    darkMode,
    uploaded,
    likes,
    isMobile,
    isLike,
    isBookmark,
    handleAddBookmark,
    handleAddFav}) => {
    const className = isMobile ? 'userInteractionBoxMobile' : 'cardFooter';
    const [isLikeStatus, setIsLikeStatus] = useState(isLike);
    const [isBookmarkStatus, setIsBookmarkStatus] = useState(isLike);

    useEffect(() => {
        setIsLikeStatus(isLike);
    }, [isLike]);
    useEffect(() => {
        setIsBookmarkStatus(isBookmark);
    }, [isBookmark]);
    return (
        <Box className={styles[className]}>
            <Box direction="column">
                <Stack direction="row" alignItems="center">
                    <IconButton sx={{
                        padding: 0
                    }}>
                        <Typography component="span" color="secondary">
                            <HiOutlineClock className={styles.footerIcons} />
                        </Typography>
                    </IconButton>
                    <Typography
                        variant="caption"
                        component="span"
                        color={darkMode ? "#ffffff" : "#121212"}
                    >
                        {`${uploaded?.days}d ${uploaded?.hours}hr ago`}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                    <Box className={styles.userInteractionBox}>
                        <IconButton  sx={{
                            padding: 0
                        }}>
                            <Typography color="#e23e58" component="span">
                                {!isLikeStatus ? (<AiOutlineHeart className={styles.footerIcons} onClick={handleAddFav}/>) 
                                : (<AiFillHeart className={styles.footerIcons} onClick={handleAddFav}/>)
                                }
                            </Typography>
                        </IconButton>
                        {/* <Typography
                            variant="caption"
                            component="span"
                            color={darkMode ? "#ffffff" : "#121212"}
                        >
                            
                        </Typography> */}
                    </Box>
                    <Box>
                        <IconButton  sx={{
                            padding: 0
                        }}>
                            <Typography component="span" color="secondary">
                                {!isBookmarkStatus ? (<BsBookmark className={styles.footerIcons} onClick={handleAddBookmark}/>)
                                : (<BsFillBookmarkFill className={styles.footerIcons} onClick={handleAddBookmark}/>)}
                            </Typography>
                        </IconButton>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}