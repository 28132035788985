import React, { useState, useEffect } from "react";
import {
  Avatar,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { useNavigate, useLocation, Outlet } from "react-router-dom"
import { useTheme } from "@emotion/react";
import { useGetUserDetailsQuery } from 'services/api.slice';
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { defaultPngAvatar } from "configs/constant";

// Cover
import ProfileCover from "assets/userProfileCover.png";

const ProfileInterface = ({ darkMode }) => {
  // const user = useSelector((state) => state.auth.user);
  const {data: user} = useGetUserDetailsQuery('userDetails');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const [imageLoading, setImageLoading] = useState(true);

  const { t } = useTranslation();
  useEffect(() => {
  }, [location]);
  return (
    <>
      {!isMobile ? (
        <>
          {location.pathname === "/profile/user/edit-profile" ? (
            <Box
              sx={{
                color: `${darkMode ? "#ffffff" : "#040404"}`,
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <Outlet />
            </Box>
          ) : (
            <Box
              sx={{
                color: `${darkMode ? "#ffffff" : "#040404"}`,
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <Box position="relative">
                <img
                  src={user?.cover ? user?.cover : ProfileCover}
                  alt="Profile Cover"
                  height={"300px"}
                  onLoad={() => setImageLoading(false)}
                  hidden={imageLoading}
                  width={"100%"}
                  style={{ opacity: "0.9", filter: "brightness(0.9)" }}
                />
                {imageLoading && <Skeleton
                    sx={{
                    backgroundColor: `${
                        darkMode ? "#171C26" : "#fff2f8"
                    }`,
                    }}
                    animation="wave"
                    variant="rectangular"
                    style={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "20px",
                    }}
                />}
                <Box position="absolute" sx={{ top: 0, right: 0 }}>
                  <IconButton onClick={() => navigate("/profile/edit-profile")}>
                    <ModeEditIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ px: 4 }}>
                <Box sx={{ textAlign: "center", position: "relative", mt: -8 }}>
                  <Avatar
                    sx={{
                      width: "100px",
                      height: "100px",
                      border: `1px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                    src={user?.image ? user.image : defaultPngAvatar}
                    alt={user?.username}
                  />
                  <Box
                    sx={{ position: "absolute", bottom: "-15%", left: "4.5%" }}
                  >
                    <IconButton>
                      <PhotoCameraIcon />
                    </IconButton>
                  </Box>
                </Box>
                {/* Content Box */}
                <Stack
                  mt={4}
                  direction="row"
                  gap={2}
                  justifyContent="space-between"
                >
                  <Box width={"60%"}>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography variant="h6">{user?.full_name}</Typography>
                      <Typography variant="subtitle2" color="GrayText">
                        @{user?.username}
                      </Typography>
                    </Box>
                    {/* Bio box */}
                    <Box mt={3}>
                      <Typography variant="subtitle1">Bio</Typography>
                      <Typography variant="body2" color="GrayText">
                        {user?.bio}
                      </Typography>
                    </Box>
                  </Box>
                  <Box width={"40%"}>
                    <Typography
                      mb={4}
                      variant="subtitle1"
                      sx={{ color: "secondary" }}
                    >
                      {t("CONTACT_INFO")}
                    </Typography>
                    <Box mb={1}>
                      <Stack direction="row" gap={1}>
                        <Box>
                          <EmailOutlinedIcon sx={{ color: "secondary" }} />
                        </Box>
                        <Typography variant="body1">
                          {user?.email}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box>
                      {/* <Stack direction="row" gap={1}>
                        <Box>
                          <LocalPhoneOutlinedIcon sx={{ color: "secondary" }} />
                        </Box>
                        <Typography variant="body1">{user?.phone}</Typography>
                      </Stack> */}
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          {location.pathname === "/profile/user/edit-profile" ? (
            <Box
              sx={{
                color: `${darkMode ? "#ffffff" : "#040404"}`,
                borderRadius: "10px",
              }}
            >
              <Outlet />
            </Box>
          ) : (
            <Box
              sx={{
                color: `${darkMode ? "#ffffff" : "#040404"}`,
                backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                borderRadius: "10px",
                height: "100%",
                pb: 3,
              }}
            >
              <Box position="relative">
                <img
                  src={user?.cover ? ProfileCover : user?.cover}
                  alt="Profile Cover"
                  onLoad={() => setImageLoading(false)}
                  hidden={imageLoading}
                  height={"300px"}
                  width={"100%"}
                  style={{ opacity: "0.9", filter: "brightness(0.9)" }}
                />
                {imageLoading && <Skeleton
                    sx={{
                    backgroundColor: `${
                        darkMode ? "#171C26" : "#fff2f8"
                    }`,
                    }}
                    animation="wave"
                    variant="rectangular"
                    style={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "20px",
                    }}
                />}
                <Box position="absolute" sx={{ top: 0, right: 0 }}>
                  <IconButton onClick={() => navigate("/profile/edit-profile")}>
                    <ModeEditIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ px: 4 }}>
                <Box sx={{ textAlign: "center", position: "relative", mt: -8 }}>
                  <Avatar
                    sx={{
                      width: "100px",
                      height: "100px",
                      border: `1px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                    src={user?.image ? user.image : defaultPngAvatar}
                    alt={user?.username}
                  />
                  <Box
                    sx={{ position: "absolute", bottom: "-15%", left: "12%" }}
                  >
                    <IconButton>
                      <PhotoCameraIcon />
                    </IconButton>
                  </Box>
                </Box>
                {/* Content Box */}
                <Stack mt={4} direction="column" gap={2}>
                  <Box>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography variant="h6">{user?.full_name}</Typography>
                      <Typography variant="subtitle2" color="GrayText">
                        @{user?.username}
                      </Typography>
                    </Box>
                    {/* Bio box */}
                    <Box mt={3}>
                      <Typography variant="subtitle1">Bio</Typography>
                      <Typography variant="body2" color="GrayText">
                        {user?.bio}
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={1}>
                    <Typography mb={1} variant="subtitle1" color="secondary">
                      {t("CONTACT_INFO")}
                    </Typography>
                    <Box mb={1}>
                      <Stack direction="row" gap={1}>
                        <Box>
                          <EmailOutlinedIcon sx={{ color: "secondary" }} />
                        </Box>
                        <Typography variant="body1">
                          {user?.email}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box>
                      <Stack direction="row" gap={1}>
                        <Box>
                          <LocalPhoneOutlinedIcon sx={{ color: "secondary" }} />
                        </Box>
                        <Typography variant="body1">{user?.phone}</Typography>
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ProfileInterface;
