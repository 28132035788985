import React, { useEffect, useState, memo, lazy, Suspense } from "react";
import { Box } from "@mui/material";

import { useParams } from "react-router";

import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Backdrop from "assets/exploreBackDropCircle.svg";
import { useApolloQuery } from "hooks/useApolloQuery";
import { useMount } from "hooks/useMount";
const NftCardLists = lazy(() => import("components/NftCard/NftCardContainer/NftCardLists"));

const IndividualnftCard = ({ darkMode }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [collectionName, setCollectionName] = useState('');
    const filter = {collection: parseInt(id)};
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const { data, refetch } = useApolloQuery('collection', {
      id: parseInt(id),
    });
    const handleDelete = () => {
      navigate(-1);
    };
    // setting tab value for changing
    useMount(refetch);
    useEffect(() => {
      if (collectionName === '' && data) {
          setCollectionName(data?.collection?.name);
      }
    }, [collectionName, data, id]);

    return (
      <Box mb={3}>
        <Box mb={3}>
          <Chip
            style={{
              zIndex: "1000",
            }}
            color="success"
            variant="outlined"
            label={collectionName}
            onDelete={handleDelete}
          />
        </Box>

        <div
          style={{
            position: "fixed",
            zIndex: 500,
          }}
        >
          <img src={Backdrop} alt="Bakcdrop" />
        </div>
        <Suspense fallback={<div></div>}>
          <NftCardLists queryName={""} darkMode={darkMode} filterStatement={filter} dataField={'category'} />
        </Suspense>
      </Box>
    );
  };

  export default memo(IndividualnftCard);