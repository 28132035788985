import {
  TextField,
  MenuItem,
} from '@mui/material';

import { Chains } from 'configs/constant';

const ChainSelect = ({ darkMode, name, value, onChange }) => {
  return (
    <TextField
        id="outlined-select-currency"
        select
        name={ name }
        onChange={onChange}
        color='secondary'
        label="Category"
        defaultValue={value??Chains[0].value}
        value={value??Chains[0].value}
        helperText="Please select your currency"
        >
        {Chains.map((option) => (
            <MenuItem key={option.value} value={option.value}>
            {option.display}
            </MenuItem>
        ))}
    </TextField>
  );
};

export default ChainSelect;