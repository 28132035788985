/* eslint-disable react-hooks/rules-of-hooks */
import {
    Grid,
} from "@mui/material";
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Backdrop from "assets/exploreBackDropCircle.svg";
import { useApolloQuery } from "hooks/useApolloQuery";
import { useMount } from "hooks/useMount";
import NftCard from "components/NftCard/NftCard";


const FavouriteNft = ({searchText, auctionStatus, darkMode, userEmail,  namespace}) => {
    const [favourites, setFavourites] = useState([]);
    const navigate = useNavigate();
    const dataArea = namespace ? namespace : 'bookmark';
    const email = localStorage.getItem("email");
    let params = {
        email: userEmail ?? email,
    };
    if (searchText)
        params.searchTxt = searchText;
    if (auctionStatus)
        params.auction_status = auctionStatus;

    const { data: favouritesResult, refetch } = useApolloQuery(dataArea, params);
    useMount(refetch);
    useEffect(() => {
        refetch();
    }, [email, auctionStatus, searchText, dataArea]);

    useEffect(() => {
        if (favouritesResult) {
            setFavourites(favouritesResult[dataArea]);
        }
    }, [dataArea, namespace, favouritesResult]);
    const handleDetails = (filteredNft) => {
        if (auctionStatus) {
            navigate(`/auction/${filteredNft.collection_id}/${filteredNft.token_id}`);
        } else {
            navigate(`/explore/${filteredNft.collection_id}/${filteredNft.token_id}`);
        }
    };

    return(
        !email ? (<></>) : (
            <>
                <div
                    style={{
                    position: 'fixed',
                    zIndex: 500,
                    }}
                >
                    <img src={Backdrop} alt='Bakcdrop' />
                </div>
                <Grid item justifyContent="flex-start">
                    <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 1, sm: 12, md: 12 }}
                    sx={{padding: 0}}
                    justifyContent="flex-start"
                    >
                    {favourites?.length === 0 ? (
                        null
                    ) : (
                        <>
                        {favourites?.map((fs) =>
                            <NftCard
                                key={fs.id}
                                nft={fs?.nft}
                                handleDetails={() => handleDetails(fs?.nft)}
                                darkMode={darkMode}
                            />
                        )}
                        </>
                    )}
                    </Grid>
                </Grid>
            </>
        )
    );
};

export default FavouriteNft;