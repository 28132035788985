import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FiChevronLeft } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";

export const NftWorkNavigation = ({ darkMode, title, isMobile }) => {
    const { t } = useTranslation();
    const detail = title ? title : "ITEM_DETAILS";
    return (
        <>
            {!isMobile ? (
                <Box className={styles.topNavigationBox}>
                    <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => window.history.back()}
                    >
                        <FiChevronLeft fontSize={"1.5rem"} />
                    </Box>
                    <Typography
                        className={styles.topNavigationTypo}
                        component="div"
                        borderBottom={`2px solid ${darkMode ? "#ffffff" : "#121212"}`}
                    >
                        <Typography
                            variant="h6"
                            component="p"
                            sx={{ cursor: "pointer" }}
                            zIndex={2}
                            color={`${darkMode ? "#ffffff" : "#121212"} !important`}
                        >
                            {t(detail)}
                        </Typography>
                        {darkMode && (
                        <Typography
                            variant="h1"
                            component="p"
                            ml={-3}
                            className={styles.labelHighLighter}
                        ></Typography>
                        )}
                    </Typography>
                </Box>
            ) : (
                <Box className={styles.topNavigationBoxMobile}>
                    <Box
                        width={"70px"}
                        height={"60px"}
                        mt={-1}
                        bgcolor={`${darkMode ? "#04040400" : "#ffffff00"}`}
                    >
                        <FiChevronLeft
                        onClick={() => window.history.back()}
                        fontSize={"1.5rem"}
                        />
                    </Box>
                    <Box
                        sx={{
                        position: "fixed",
                        top: "3%",
                        zIndex: "10000",
                        width: "70%",
                        display: "flex",
                        justifyContent: "center",
                        ml: 4,
                        }}
                    >
                        <Typography
                            component="div"
                            borderBottom={`2px solid ${darkMode ? "#ffffff" : "#121212"}`}
                            position="relative"
                            display="flex"
                            alignItems="center"
                            ml={4}
                        >
                            <Typography
                                variant="subtitle1"
                                component="p"
                                sx={{ zIndex: 2 }}
                            >
                                {t(detail)}
                            </Typography>
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
}