import React, { useEffect, useState } from 'react';
import {
  useConnectModal
} from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi'
import {  useNavigate  } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { pinCollectionFileUpload, confirmCurrentNetwork, switchCurrentNetwork } from 'Utils';
import { PINATA_ASSET_BASE_URL } from 'configs/constant';

import {
  Typography,
  TextField,
  Stack,
  useMediaQuery,
  useTheme,
  Backdrop,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import Loader from 'components/Loader/Loader';
import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
// icons
import UploadImage from './UploadImage';
import CategorySelect from './CategorySelect';
import ChainSelect from './ChainSelect';
import AlertDialog from "components/Alert/AlertDialog";
import { useApolloMutation } from "hooks/useApolloQuery";
import SwitchNetworkDialog from "components/Alert/SwitchNetworkDialog";
import { useCheckedLogin } from 'hooks/useCheckedLogin';

const CreateCollection = ({ darkMode }) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { handleMutation } = useApolloMutation('createCollections');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const loginedUser = useSelector((state) => state.auth.user);
  const [isCreatingCollection, setIsCreatingCollection] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [switchNetworkModalOpen, setSwitchNetworkModalOpen] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [featuredFile, setFeaturedFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [isPinning, setIsPinning] = useState(false);
  const isSaving = isPinning;
  const savingMessage = isPinning ? 'pinning asset...' : 'save...'

  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const [values, setValues] = useState({
    name: '',
    url: '',
    description: '',
    site: '',
    instagram: '',
    discord: '',
    twitter: '',
    reddit: '',
    youtube: '',
    royalty: 7,
    category: '',
    payment: ['MATIC', 'WMATIC'],
  });

  useCheckedLogin();
  useEffect(() => {
    if (!!loginedUser?.role && loginedUser?.role !== 'creator')
      navigate('/home');
  }, [loginedUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAcceptSubmit = async () => {
    setIsSubmit(false);
    if (await confirmCurrentNetwork(values.blockchain || 'Polygon')) {
      setIsCreatingCollection(true);
      await handleCreateCollection();
    } else {
      setSwitchNetworkModalOpen(true);
    }
  }

  const onClickSwitchNetwork = async () => {
    try {
      await switchCurrentNetwork(values.blockchain || 'Polygon');
      setSwitchNetworkModalOpen(false);
      setIsCreatingCollection(true);
      handleCreateCollection();
    } catch(e) {
      console.log(e);
    }
  };

  const handleCreateCollection = async () => {
    if (isSaving) {
      return;
    }

    try {
      setIsPinning(true);
      const { logo, featured, banner } = await pinCollectionFileUpload(logoFile, featuredFile, bannerFile);
      setIsPinning(false);

      let logoURL;
      let featuredURL;
      let bannerURL;

      if (logo) {
        logoURL = `${PINATA_ASSET_BASE_URL}/${logo}`;
      }
      if (featured) {
        featuredURL = `${PINATA_ASSET_BASE_URL}/${featured}`;
      }
      if (banner) {
        bannerURL = `${PINATA_ASSET_BASE_URL}/${banner}`;
      }

      try {
        const params = {
          logo: logoURL,
          feature: featuredURL,
          banner: bannerURL,
          name: values.name,
          url: values.url,
          description: values.description,
          site: values.site,
          discord: values.discord,
          twitter: values.twitter,
          youtube: values.youtube,
          instagram: values.instagram,
          royalty: parseFloat(values.royalty),
          category: values.category === '' ? 'all'  : values.category,
          creatorWalletAddress: address,
          blockchain: values.blockchain || 'Polygon',
          contract: ''
        };

        const result = await handleMutation(params);
        if (parseInt(result?.data?.addCollection?.id) > 0) {
          showSnackMessage(t('MSG_SAVE_COLLECTION_SUCCESS'), 'success');
          setIsCreatingCollection(false);
          setTimeout(() => {
            navigate('/collections');
          }, 1000);
        } else {
          showSnackMessage(result?.data?.addCollection?.message, 'error');
          setIsCreatingCollection(false);
        }
      } catch(e) {
        showSnackMessage(t('MSG_SAVE_COLLECTION_ISSUE'), 'error');
        setIsCreatingCollection(false);
        return 0;
      }
    } catch(e) {
      showSnackMessage(t('MSG_UPLOAD_IPFS_IMAGE_ISSUE'), 'error');
      setIsCreatingCollection(false);
      return 0;
    }
  }

  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, {variant});
  }
  const handleSubmit = async () => {
    if (!isConnected) {
      await openConnectModal();
    }
    setIsSubmit(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: 70000 }}
          open={isCreatingCollection}
        ></Backdrop>

      <SwitchNetworkDialog
        open={switchNetworkModalOpen}
        handleClose = {() => setSwitchNetworkModalOpen(false)}
        onClickSwitchNetwork={onClickSwitchNetwork}
        blockchain={values.blockchain || 'Polygon'}
        darkMode={darkMode}
      />

      <AlertDialog
        open={isSubmit}
        handleClose={() => setIsSubmit(false)}
        handleAgree={handleAcceptSubmit}
        darkMode={darkMode}
        title={'ALERT_TITLE'}
        content={'ASK_CREATE_COLLECTION'}
      />
      <Loader
        isLoading={isSaving}
        message={savingMessage}
        darkMode={darkMode}
      />
      <Box
        color={darkMode ? '#ffffff' : '#121212'}
        mt={!isMobile ? 11.5 : 2}
        mb={3}
      >
        <Typography
          color='secondary'
          variant={!isMobile ? 'h3' : 'h5'}
          textAlign={isMobile ? 'center' : null}
          component='h3'
          sx={{
            lineHeight: 1.5,
            fontWeight: 500,
            width: `${!isMobile ? '60%' : '100%'}`,
          }}
        >
          {t('CREATE_COLLECTION')}
        </Typography>
        <Typography variant='subtitle2'>
          {t('REQUIRED_FIELD')}
        </Typography>
        <UploadImage caption={'Upload your Logo'} description={'Recommended size is 350 x 350'} onFileUpload={ (file) => setLogoFile(file) } />
        <UploadImage caption={'Upload Featured image'} description={'Spotlight your collection with a featured image; the recommended size is 600 x 400'}  onFileUpload={ (file) => setFeaturedFile(file) } />
        <UploadImage caption={'Upload Banner Image'} description={'Customize your homepage with a Banner image; the recommended size is 1400 x 350'}  onFileUpload={ (file) => setBannerFile(file) } />
        <Stack spacing={2}>
          <TextField
              required
              name="name"
              onChange={handleChange}
              label="name"
              color='secondary'
              placeholder=""
          />
          <TextField
              name='site'
              onChange={handleChange}
              color='secondary'
              label="URL"
              placeholder="https://yoursite.io/item/123"
              hypertext="Create a custom URL on NFT Rewards: lowercase letters, numbers, and hyphens"
          />
          <TextField
              name='discord'
              onChange={handleChange}
              color='secondary'
              label="Discord"
              placeholder="https://yoursite.io/item/123"
              hypertext="Create a custom URL on NFT Rewards: lowercase letters, numbers, and hyphens"
          />
          <TextField
              name='twitter'
              onChange={handleChange}
              color='secondary'
              label="TWITTER"
              placeholder="https://yoursite.io/item/123"
              hypertext="Create a custom URL on NFT Rewards: lowercase letters, numbers, and hyphens"
          />
          <TextField
              name='youtube'
              onChange={handleChange}
              color='secondary'
              label="YOUTUBE"
              placeholder="https://yoursite.io/item/123"
              hypertext="Create a custom URL on NFT Rewards: lowercase letters, numbers, and hyphens"
          />
          <TextField
              name='instagram'
              onChange={handleChange}
              color='secondary'
              label="INSTAGRAM"
              placeholder="https://yoursite.io/item/123"
              hypertext="Create a custom URL on NFT Rewards: lowercase letters, numbers, and hyphens"
          />
          <TextField
              multiline
              name='description'
              onChange={handleChange}
              color='secondary'
              label="Describe your item"
              placeholder="Provide a detailed description of your itme"
              hypertext="1000 characters Max"
          />
          <CategorySelect
            name='category'
            onChange={handleChange}
          />
          {/* TODO: Logo image */}
          <ChainSelect
            name="blockchain"
            onChange={handleChange}/>
          {/* TODO: Payment Tokens */}
        </Stack>
        <GradientButtonPrimary
          onClick={handleSubmit}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="subtitle1" component="span">
            {t("Submit")}
          </Typography>
        </GradientButtonPrimary>
      </Box>
    </>
  );
};

export default CreateCollection;
