import { useEffect } from 'react';

export const useMount = (mount, disMount) => {
    useEffect(() => {
        if (mount && (typeof mount).toLocaleUpperCase() === ('Function').toLocaleUpperCase()) {
            mount();
        }

        return () => {
            if (disMount && (typeof disMount).toLocaleUpperCase() === ('Function').toLocaleUpperCase()) {
                disMount();
            }
        }
    }, []);
}