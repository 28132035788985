import React, { useState, useEffect } from "react";
import { Grid, Typography, useMediaQuery, Card, CardContent, Button, Stack, TextField, MenuItem, } from "@mui/material";
import { Box } from "@mui/system";
import BackgroundWrinkles1 from "assets/BackgroundWrinkles1.svg";
import BackgroundWrinklesLight from "assets/backgroundWrinklesLight.svg";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from 'notistack';
import { useTheme } from "@emotion/react";
import { useDisconnect, useAccount } from 'wagmi';
import {
  useConnectModal,
} from '@rainbow-me/rainbowkit';
import { useNavigate,} from 'react-router-dom';
import { PAYABLE_AMOUNT } from "configs/constant";
import { useApolloQuery, useApolloMutation } from "hooks/useApolloQuery";
import { useMount } from "hooks/useMount";
import { useCheckedLogin } from "hooks/useCheckedLogin";
import AlertDialog from "components/Alert/AlertDialog";
import { CopyNftInfoDisplay } from "components/NftCard/NftCardDetails/Component";
import QRCode from "react-qr-code";
import { IoCart } from "react-icons/io5";
import { useStyles } from 'components/Alert/style';
import { notifyAlertDeposit } from "slices/graphql.slice";
import { DepositExpiration } from "./DepositExpiration";
import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
import styles from "components/Auction/AuctionCardDetails/AuctionCard.module.css";

const Deposit = ({ darkMode }) => {
  const [checkedCrypto, setCheckedCrypto] = useState({});
  const { openConnectModal } = useConnectModal();
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isDeposit, setIsDeposit] = useState(false);
  const [isDepositStep, setIsDepositStep] = useState(false);
  const [cryptoAddress, setCryptoAddress] = useState('');
  const [checkedAmount, setCheckedAmount] = useState(0);
  const [transactionId, setTransactionId] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const qrColor = darkMode ? '#FFFFFF' : '#000000';
  const qrBackgroundColor = darkMode ? '#000000' : '#FFFFFF';
  const loginedUser = useSelector((state) => state.auth.user);
  const { data , refetch } = useApolloQuery('payment', {});
  const { handleMutation } = useApolloMutation('deposit');
  const { handleMutation: handleCancelMutation } = useApolloMutation('cancel');
  useMount(refetch);
  const alertPay = useSelector(((state) => state?.nft?.alertPay));
  dispatch(notifyAlertDeposit(loginedUser?.email));
  useEffect(() => {
    const sendTransactionRequest = async () => {
      if ( !!isConnected && !!isDepositStep && !!address) {
        const result = await handleMutation({
          crypto_id: parseInt(checkedCrypto?.id),
          amount: parseInt(checkedAmount),
          address: address,
        });
        if (!result?.data?.deposit?.status) {
          showSnackMessage(result?.data?.deposit?.message, 'error');
        } else {
          showSnackMessage(result?.data?.deposit?.message, 'success');
          setCryptoAddress((prev) => result?.data?.deposit?.address);
          setTransactionId(result?.data?.deposit?.id);
        }
      }
    }
    sendTransactionRequest();
  }, [isConnected, isDepositStep]);

  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, {variant});
  }
  useCheckedLogin();

  useEffect(() => {
    if ( alertPay?.amount > 0 && alertPay?.email === loginedUser?.email ) {
      showSnackMessage(`$${alertPay?.amount} Deposit Successfuly.`, 'success');
      setIsDeposit(false);
      setIsDepositStep(false);
    }
  }, [alertPay])
  const handleClickBtn = (e) => {
    const { value } = e.target;
    const crypto = data?.payment?.cryptos[value];
    setCheckedCrypto((prev) => crypto);
    if (isConnected) {
      disconnect();
    }
  }

  const handleClickCheckAmount = (e) => {
    const { value } = e.target;
    setCheckedAmount(value);
  }

  const handleClickDeposit = () => {
    if (!checkedCrypto?.id || !checkedAmount) {
      showSnackMessage('You must check available crypto currency and amount.', 'error');
      return;
    }
    setIsDeposit(true);
  }
  const handleAgreeDeposit = async () => {
    setIsDeposit(false);
    if (!checkedCrypto?.id || !checkedAmount) {
      showSnackMessage('You must check available crypto currency and amount.', 'error');
      return;
    }
    if (!!isConnected) {
      await disconnect();
    }

    setTimeout(() => {
      if (!isConnected) {
        openConnectModal();
      }
    }, 1000);
    setIsDepositStep(true);
    setTransactionId(0);
  }

  const handleClickCancelDeposit = async () => {
    setIsDepositStep(false);
    handleCancelMutation({
      id: parseInt(transactionId)
    });
    setTransactionId(0);
    await disconnect();
  }

  const getAbstractStr = (text) => {
    return text?.substring(0, 6) + '...' + text?.substring(text?.length - 4, text?.length)
  }

  return (
    <>
      <AlertDialog
        open={isDeposit}
        handleClose={() => setIsDeposit(false)}
        handleAgree={handleAgreeDeposit}
        darkMode={darkMode}
        title={'ALERT_TITLE'}
        content={'ASK_DEPOSIT'}
      />
      <Box
        color={darkMode ? "#ffffff" : "#171c26"}
        sx={{
          position: "relative",
        }}
      >
        <Box
          style={{
            position: "fixed",
            height: "100vh",
          }}
        >
          <img
            src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
            alt="Wrinkles"
          />
        </Box>
        <Box sx={!isMobile ? { mt: 11 } : { mt: 2 }}>
          {!isMobile ? (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                gap: 4,
                alignItems: "center",
              }}
            >
              <Typography
                component="div"
                sx={{
                  borderBottom: `2px solid ${darkMode ? "#ffffff" : "#171c26"}`,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ zIndex: 2, cursor: "pointer" }}
                >
                  {t("DEPOSIT")}
                </Typography>
                {darkMode && (
                  <Typography
                    variant="h1"
                    component="p"
                    sx={{
                      background:
                        "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)",
                      borderRadius: "4px",
                      width: "35px",
                      height: "24px",
                      ml: -4,
                    }}
                  ></Typography>
                )}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "fixed",
                  top: "3%",
                  zIndex: "10000",
                  width: "75%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box pb={2} ml={6}>
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    component="div"
                    sx={{
                      borderBottom: `${
                        darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                      }`,
                    }}
                  >
                    {t("DEPOSIT")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{
              backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "95%",
              marginLeft: "auto",
              marginRight: "auto",
              mt: 3,
          }}>
            <Grid container>
              <Card sx={{ width: "100vw" }}>
                <CardContent>
                {loginedUser?.role === 'payment-creator' ? (<Box sx={{ textAlign: 'right' }} mb={1}>
                    <Button
                      onClick={() => navigate('/create-crypto')}
                      variant='outlined'
                      color='secondary'
                      sx={{
                        alignItems: 'flex-end'
                      }}
                    >
                      {t('CREATE')}
                    </Button>
                  </Box>) : null }
                  <Grid container spacing={2}>
                    <Grid item xs={isMobile ? 12 : 6} direction="column">
                      <Box sx={{
                        width: "100%"
                      }}>
                        <TextField
                          select
                          name="crypto"
                          label={t("CRYPTO")}
                          fullWidth
                          required="required"
                          color='secondary'
                          onChange = {handleClickBtn}
                          sx={{mt: 1}}
                          >
                          {data?.payment?.cryptos?.map((crypto, index) => (
                            <MenuItem key={crypto.id} value={index}>
                              <div>
                                <img src={crypto?.image ? crypto?.image : ''} alt={crypto?.name} width="20" height={20}/>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  fontSize={"20px"}
                                  marginLeft={"10px"}>{crypto?.name}</Typography>
                              </div>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box sx={{
                          width: "100%"
                        }}
                        mt={1}
                      >
                        <TextField
                          select
                          name="amount"
                          label={t("AMOUNT")}
                          fullWidth
                          required="required"
                          color='secondary'
                          onChange = {handleClickCheckAmount}
                          sx={{mt: 1}}
                          >
                          {!!checkedCrypto?.id && PAYABLE_AMOUNT?.map((amount, index) => (
                            <MenuItem key={index} value={amount} alignItems="center">
                              <div>
                                <img src={checkedCrypto?.image ? checkedCrypto?.image : ''} alt={checkedCrypto?.name} width="20" height={20}/>
                                <Typography 
                                  variant="body1"
                                  component="span"
                                  fontSize={"20px"}
                                  marginLeft={"10px"}
                                >${amount}</Typography>
                              </div>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      {!!checkedCrypto?.blockchain && !!checkedAmount && !isConnected && (<Box sx={{ textAlign: 'right', width: "100%" }} mb={3} mt={3} xs={12}>
                        <GradientButtonPrimary
                          className={styles.gradientButtonClass}
                          variant="contained"
                          onClick={() => handleClickDeposit()}
                          sx={{
                              marginTop: "1rem",
                              flexShrink: 1,
                          }}
                        >
                          <Typography
                              color="#ffffff"
                              component="span"
                              fontSize={20}
                              mt={0.5}
                          >
                              <IoCart />
                          </Typography>
                          <Typography variant="body1" component="span">
                            {t('DEPOSIT')}
                          </Typography>
                        </GradientButtonPrimary>
                      </Box>)}
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                      {isDepositStep && !!isConnected && (<>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}>
                          <DepositExpiration darkMode={darkMode} handleExpired = {handleClickCancelDeposit}/>
                          <Box marginLeft={2}>
                            <Typography component="p" sx={{}}>{t('WAITING')}</Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>{t("PAY_DESCRIPTION")}</Typography>
                          </Box>
                        </Box>
                        <Grid container alignItems="center" justifyContent="center" sx={{mt: 2}}>
                          <Stack xs={12} sx={{width: "100%"}}>
                            {!!checkedCrypto?.blockchain && <CopyNftInfoDisplay
                              darkMode={darkMode}
                              copyValue={`${cryptoAddress}`}
                              label={`${t('DEPOSIT_ADDRESS')}: `}
                            >
                              <Typography sx={{ textAlign: "right" }}>{`${getAbstractStr(cryptoAddress)}`}</Typography>
                            </CopyNftInfoDisplay>}
                          </Stack>
                          <Stack xs={12} sx={{width: "100%"}}>
                            {!!checkedAmount && <CopyNftInfoDisplay
                              darkMode={darkMode}
                              copyValue={checkedAmount}
                              label={`${t('Amount')}: `}
                            >
                              <Stack>
                                <Typography sx={{ textAlign: "right" }} xs={12}>{`$${checkedAmount}`}</Typography>
                              </Stack>
                            </CopyNftInfoDisplay>}
                          </Stack>
                          {!!checkedCrypto?.blockchain && (<QRCode
                            value={`${cryptoAddress}`}
                            level="Q"
                            fgColor={qrColor}
                            bgColor={qrBackgroundColor}
                            ></QRCode>)}
                        </Grid>
                        <Box sx={{ textAlign: 'right', width: "100%" }} mb={3} mt={3} xs={12}>
                          {/* <Button
                            onClick={() => handleClickCancelDeposit()}
                            variant='outlined'
                            color='secondary'
                            sx={{
                              alignItems: 'flex-end'
                            }}
                          >
                            {t('CANCEL')}
                          </Button> */}
                        </Box>
                      </>)}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Deposit;
