import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';

import BackgroundWrinkles1 from 'assets/BackgroundWrinkles1.svg';
import BackgroundWrinklesLight from 'assets/backgroundWrinklesLight.svg';
import Backdrop from 'assets/exploreBackDropCircle.svg';
import { Grid } from '@mui/material';
import CollectionCard from '../CollectionCard/CollectionCard';
import { useApolloQuery } from "hooks/useApolloQuery";
import { useMount } from "hooks/useMount";

const CollectionContainer = ({ darkMode, searchText, sortBy, orderBy }) => {
  const [collections, setCollections] = useState([]);
  const params = {
    name: searchText,
    sort_field: sortBy,
    order: orderBy,
  };

  const { data: colInfos, error, refetch } = useApolloQuery('collections', params);

  useMount(() => {
    refetch();
  });

  useEffect(() => {
    refetch();
  }, [searchText, sortBy, orderBy]);

  useEffect(() => {
    if (colInfos && !error)
      setCollections(colInfos?.collectionsSearch);
  }, [colInfos, error]);

  return (
    <Box>
      <Box
        style={{
          position: 'fixed',
          height: '100vh',
        }}
      >
        <img
          src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
          alt='Wrinkles'
        />
      </Box>
      <div style={{ position: 'fixed', left: '25%' }}>
        {darkMode && <img src={Backdrop} alt='Backdrop' />}
      </div>
      <Box mt={8}>
        <Grid
          container
          spacing={{ xs: 4, md: 6 }}
          columns={{ xs: 1, sm: 12, md: 12 }}
        >
          {collections?.map((collection) => (
            <CollectionCard
              darkMode={darkMode}
              key={collection.id}
              id={collection.id}
              collection={collection}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CollectionContainer;
