import { useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const useCheckedLogin = (condition) => {
    const accessToken = Cookies.get('access_token');;
    const navigate = useNavigate();
    const addCondition = condition ?? true;

    useEffect(() => {
        if (addCondition && !accessToken) {
            navigate('/authentication/sign-in');
            return;
        }
        const decodedToken = jwtDecode(accessToken);
        if (addCondition && decodedToken?.exp <= Date.now() / 1000) {
            navigate('/authentication/sign-in');
            return;
        }
    }, [accessToken, navigate, addCondition]);
}