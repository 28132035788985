import React, { useState, useEffect } from 'react';
import {
    IconButton,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ImImage } from 'react-icons/im';
import { TiTimes } from 'react-icons/ti';
import { styled } from "@mui/material/styles";

const UploadImage = ({ darkMode, caption, description, value, onFileUpload}) => {
    const Input = styled("input")({
        display: "none",
    });

    const [image, setImage] = useState(value??null);
    useEffect(() => {
        if(value) {
            setImage(value);
        }
    }, [value]);
    const handleImageUpload = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        onFileUpload(e.target.files[0]);
    };

    return (
        <Box sx={{
            my: 4
        }}>
            <Typography variant='body1'>
                { caption }
            </Typography>
            <Typography variant='caption'>
                { description }
            </Typography>
            <Box>
            {!image ? (
                <Box
                    sx={{
                        my: 2,
                        border: '2px solid #c4c4c4',
                        borderStyle: 'dashed',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        p: 3,
                        height: '150px',
                    }}
                >
                <label htmlFor='icon-button-file-front'>
                    <Input
                        accept='image/*'
                        id='icon-button-file-front'
                        type='file'
                        onChange={handleImageUpload}
                        required
                    />
                    <IconButton
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                    >
                        <Typography
                        component='span'
                        color='secondary'
                        fontSize={30}
                        >
                            <ImImage />
                        </Typography>
                    </IconButton>
                </label>
            </Box>
            ) : (
                <Box sx={{ my: 2, position: 'relative' }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            left: '1%',
                            top: '4%',
                        }}
                    >
                        <IconButton
                            sx={{
                            backgroundColor: `${
                                darkMode ? '#fff2f8' : '#171c26'
                            }`,
                            '&:hover': {
                                backgroundColor: `${
                                darkMode ? '#fff2f8' : '#171c26'
                                }`,
                                opacity: '0.8',
                            },
                            }}
                            onClick={() => {
                            setImage(null);
                            }}
                        >
                            <TiTimes
                            fontSize={'1rem'}
                            color={darkMode ? '#171c26' : '#ffffff'}
                            />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            border: '1px solid #c4c4c4',
                            borderStyle: 'dashed',
                            padding: '5px',
                            width: "315px",
                        }}
                    >
                        <img
                            style={{ height: '150px' }}
                            src={image}
                            alt='Uploaded'
                            width="315px"
                        />
                    </Box>
                </Box>
            )}
        </Box>
    </Box>
    );
}

export default UploadImage;