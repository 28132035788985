import FormData from "form-data";
import axios from "axios";
import { PINATA_API_KEY, PINATA_SECRET_API_KEY } from '../configs/constant';

// call the keys from .env
const API_KEY = PINATA_API_KEY;
const API_SECRET = PINATA_SECRET_API_KEY;

// the endpoint needed to upload the file
const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

const urlJSON = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

export const pinJsonToIPFS = async (metadata) => {
  let response;
  try {
    response = await axios.post(
      urlJSON,
      JSON.stringify({ pinataContent: metadata }),
      {
        maxContentLength: Infinity,
        headers: {
          "Content-Type": "application/json",
          pinata_api_key: API_KEY,
          pinata_secret_api_key: API_SECRET,
        },
      }
    );
  } catch (error) {
    console.log("pin json to ipfs error: ", error);
  }

  return response?.data.IpfsHash;
};

export const pinFileToIPFS = async (file, metadata) => {

  // initialize the form data
  const formData = new FormData();
  formData.append("file", file);
  formData.append("metadata", metadata);

  let response;

  try {
    response = await axios.post(url, formData, {
      maxContentLength: Infinity,
      headers: {
        "Content-Type": "multipart/form-data;",
        pinata_api_key: API_KEY,
        pinata_secret_api_key: API_SECRET,
      },
    });
  } catch (error) {
    console.log("pin file to ipfs error: ", error);
  }

  return response?.data.IpfsHash;
};

export const pinCollectionFileUpload = async (
  logo,
  featured,
  banner
) => {
  // eslint-disable-next-line no-unreachable
  let response1;
  let response2;
  let response3;

  try {
    if (logo) {
      const formData1 = new FormData();
      formData1.append("file", logo);
      response1 = await axios.post(url, formData1, {
        maxContentLength: Infinity,
        headers: {
          "Content-Type": "multipart/form-data;",
          pinata_api_key: API_KEY,
          pinata_secret_api_key: API_SECRET,
        },
      });
    }
  } catch (error) {
    console.log("pin file1 to ipfs error: ", error);
  }

  if (featured) {
    if (featured) {
      const formData2 = new FormData();
      formData2.append("file", featured);

      try {
        response2 = await axios.post(url, formData2, {
          maxContentLength: Infinity,
          headers: {
            "Content-Type": "multipart/form-data;",
            pinata_api_key: API_KEY,
            pinata_secret_api_key: API_SECRET,
          },
        });
      } catch (error) {
        console.log("pin file2 to ipfs error: ", error);
      }
    }
  }

  if (banner) {
    const formData3 = new FormData();
    formData3.append("file", banner);

    try {
      response3 = await axios.post(url, formData3, {
        maxContentLength: Infinity,
        headers: {
          "Content-Type": "multipart/form-data;",
          pinata_api_key: API_KEY,
          pinata_secret_api_key: API_SECRET,
        },
      });
    } catch (error) {
      console.log("pin file3 to ipfs error: ", error);
    }
  }

  // return response1?.data.IpfsHash
  return {
    logo: response1?.data.IpfsHash,
    featured: response2?.data.IpfsHash,
    banner: response3?.data.IpfsHash,
  };
};