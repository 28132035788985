import React from 'react';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import DummyUserProfile from '../DummyUser/DummyUserProfile';

const SellersDetails = ({ darkMode }) => {
  const { id } = useParams();
  return (
    <Box
      sx={{
        mt: 11,
      }}
    >
      <DummyUserProfile darkMode={darkMode} userId={id}/>
    </Box>
  );
};

export default SellersDetails;
