import React, { useEffect, useState, lazy, Suspense  } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useMount } from "hooks/useMount";
import { useApolloQuery } from "hooks/useApolloQuery";

const WebHome = lazy(() => import('./WebHome'));
const MobileHome = lazy(() => import('./MobileHome'));

const Home = ({ darkMode }) => {
  const theme = useTheme();
  const [homeData, setHomeData] = useState();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data: statisticData, loading, error, refetch } = useApolloQuery('statistics', {});
  useMount(() => {
    refetch();
  });
  // useEffect(() => {
  //   if (isLoaded) {
  //     setHomeData(statisticData);
  //   }
  // }, [isLoaded, statisticData])

  useEffect(() => {
    if (!error && statisticData) {
      setHomeData(statisticData);
    }
  }, [statisticData]);

  return (
    <>
      {!isMobile &&
        <Suspense fallback={<div>loading...</div>}>
          <WebHome darkMode={darkMode} statistics={homeData}/>
        </Suspense>
      }
      {isMobile &&
        <Suspense fallback={<div>loading...</div>}>
          <MobileHome darkMode={darkMode} statistics={homeData}/>
        </Suspense>}
    </>
  );
};

export default Home;
