/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { useConnectModal } from "@rainbow-me/rainbowkit";

import {
  Container,
  Typography,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import {
  getContractSignature,
  confirmCurrentNetwork,
  switchCurrentNetwork,
  listing,
  setApprove,
  getTodayTmp,
} from "Utils";
// Styles
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";
import parentStyles from "components/NftCard/NftCardDetails/NftCardDetails.module.css";
import { FixedComponent } from "components/NftCard/NftCardDetails/FixedComponent";
import { OpenedComponent } from "components/NftCard/NftCardDetails/OpenedComponent";
import { useApolloMutation, useApolloQuery } from "hooks/useApolloQuery";
import { SellType } from "configs/constant";
import SwitchNetworkDialog from "components/Alert/SwitchNetworkDialog";
import { useMount } from "hooks/useMount";

import ListingDialog from "components/Alert/ListingDialog";
import {
  NftWorkNavigation,
  NftImage,
  NftResource,
} from "components/NftCard/NftCardDetails/Component";
import { useCheckedLogin } from "hooks/useCheckedLogin";

const SellComponent = ({ darkMode }) => {
  const { collectionId, tokenId } = useParams(); // Read from url
  const [nft, setNft] = useState({});
  const [switchNetworkModalOpen, setSwitchNetworkModalOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { handleMutation: handleUpdateSell } = useApolloMutation("updateSell");
  const { handleMutation: handleSetApproved } =
    useApolloMutation("setIsApproved");
  const { handleMutation: handleUpdateCollection } =
    useApolloMutation("updateCollection");
  const user = useSelector((state) => state.auth.user);
  const [currentUser, setCurrentUser] = useState(user);
  const { t } = useTranslation();

  useCheckedLogin();

  let params = {
    collection_id: parseInt(collectionId),
    token_id: parseInt(tokenId),
    user: currentUser?.id ?? 0,
  };
  const { data: sellData, refetch } = useApolloQuery("token", params);

  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  useMount(refetch);
  // States
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [submitData, setSubmitData] = useState({
    auction_start_at: 0,
    auction_end_at: 0,
    price: parseFloat(0.01),
    collection_id: parseInt(collectionId),
    token_id: parseInt(tokenId),
    signature: "",
    uid: nanoid(),
    todayTmp: getTodayTmp(),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fixedButtonToggler, setFixedButtonToggler] = useState(true);
  const [openButtonToggler, setOpenButtonToggler] = useState(false);
  const [sellTypeChanged, setSellTypeChanged] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showListingDialog, setShowListingDialog] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sellData) {
      const nftcardInfo = sellData.nftcardFromTokenId;
      setNft(nftcardInfo);
      setSubmitData((prevState) => ({
        ...prevState,
        price: 0.01,
        // price: nftcardInfo?.last_price,
      }));
    }
  }, [sellData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFixedToggler = () => {
    setSellTypeChanged(true);
    setFixedButtonToggler(true);
    setOpenButtonToggler(false);
  };

  const handleOpenToggler = () => {
    setSellTypeChanged(true);
    setOpenButtonToggler(true);
    setFixedButtonToggler(false);
  };
  const handleChange = (field, value) => {
    if (field === "price") {
      value = parseFloat(value);
    }
    setSubmitData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleListing = async () => {
    if (!currentUser?.email) {
      showSnackMessage(t("MUST_LOGIN"), "error");
      setLoading(false);
      return;
    }
    const isOwner = nft?.owner?.id === nft?.blockchain_owner?.id;
    if (!isConnected && isOwner) {
      showSnackMessage(t("NEED_WALLET"), "error");
      openConnectModal();
      setShowListingDialog(false);
      return;
    }
    if (!isOwner) {
      await handleSubmiting();
      return;
    }
    try {
      if (await confirmCurrentNetwork(nft?.blockchain)) {
        await handleSubmiting();
      } else {
        setSwitchNetworkModalOpen(true);
      }
    } catch (e) {
      setIsSubmitting(false);
      console.log(e);
      showSnackMessage(t("MSG_FAILED"), "error");
    }
  };

  const onClickSwitchNetwork = async () => {
    try {
      await switchCurrentNetwork(nft?.blockchain);
      setSwitchNetworkModalOpen(false);
      // handleSubmiting();
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmiting = async () => {
    setShowListingDialog(true);
    setIsSubmitting(true);
    setCurrentStep(2);
    try {
      const isOwner = nft?.owner?.id === nft?.blockchain_owner?.id;
      if (!isOwner) {
        setCurrentStep(5);
        const basicParam = {
          ...submitData,
          sell_type: sellTypeChanged
            ? openButtonToggler
              ? SellType.TIMED
              : SellType.FIXED
            : nft?.sell?.sell_type,
        };
        const response = await handleUpdateSell(basicParam);
        if (response?.data?.updateSell?.success) {
          showSnackMessage(t("MSG_SUCCESS"), "success");
          navigate(`/auction/${nft?.collection_id}/${nft?.token_id}`);
        } else {
          showSnackMessage(t(response?.data?.updateSell?.message), "error");
        }

        setIsSubmitting(false);
        setShowListingDialog(false);

        return;
      }
      setCurrentStep(3);
      const contract = await listing({ tokenInfo: nft });
      submitData.contract = contract;
      setCurrentStep(4);
      let signature = "";

      if (nft?.owner?.id === nft?.blockchain_owner?.id) {
        console.log("herheh");
        signature = await getContractSignature(submitData, nft);
        console.log("lolololo");
      }
      setCurrentStep(5);
      const approveStatus = await setApprove({
        tokenInfo: nft,
        contractAddr: contract,
      });
      if (approveStatus) {
        handleSetApproved({
          collection_id: parseInt(collectionId),
          token_id: parseInt(tokenId),
        });
      }

      setSubmitData((prevState) => ({
        ...prevState,
        contract: contract ? contract : nft.collection.contract,
      }));

      if (!nft?.collection?.contract) {
        handleUpdateCollection({
          id: parseInt(nft?.collection.id),
          contract: contract,
        });

        handleSetApproved({
          collection_id: parseInt(collectionId),
          token_id: parseInt(tokenId),
        });
      }

      const basicParam = {
        ...submitData,
        wallet_address: signature.wallet,
        signature: signature.signature,
        sell_type: sellTypeChanged
          ? openButtonToggler
            ? SellType.TIMED
            : SellType.FIXED
          : nft?.sell?.sell_type,
      };

      const result = await handleUpdateSell(basicParam);

      if (!parseInt(result?.data?.updateSell?.success)) {
        showSnackMessage(t(result?.data?.updateSell?.message), "error");
        setIsSubmitting(false);
        setShowListingDialog(false);
        return;
      }

      showSnackMessage(t("MSG_SUCCESS"), "success");
      setIsSubmitting(false);
      setShowListingDialog(false);
      setTimeout(() => {
        navigate(`/auction/${collectionId}/${tokenId}`);
      }, 1000);
    } catch (e) {
      setIsSubmitting(false);
      setShowListingDialog(false);
      console.log(e);
      showSnackMessage(t("MSG_FAIL"), "error");
    }
  };
  return (
    // Nftwork details information
    <>
      <ListingDialog
        open={showListingDialog}
        darkMode={darkMode}
        blockchain={nft?.blockchain}
        price={submitData?.price ? submitData?.price : 0}
        currentStep={currentStep}
      />
      <SwitchNetworkDialog
        open={switchNetworkModalOpen}
        handleClose={() => setSwitchNetworkModalOpen(false)}
        onClickSwitchNetwork={onClickSwitchNetwork}
        blockchain={nft?.blockchain}
        darkMode={darkMode}
        zIndex={35000}
      />
      <Container className={parentStyles.nftCardDetailsContainer}>
        <Typography variant="h3" color="secondary.main" component="div">
          <Box mt={11} className={styles.detailsContainerBox}>
            {/* Top navigation */}
            <NftWorkNavigation
              title={"ITEM_SELL"}
              darkMode={darkMode}
              isMobile={isMobile}
            />
            {/* Details container */}
            {!isMobile ? (
              <Box className={styles.detailsContainer}>
                <Box zIndex={10}>
                  <NftImage
                    darkMode={darkMode}
                    isMobile={isMobile}
                    tokenURI={nft?.token_uri}
                    name={nft?.name}
                  />
                  <NftResource
                    darkMode={darkMode}
                    tokenURI={nft?.token_uri}
                    contract={nft?.collection?.contract}
                    token_id={nft?.token_id}
                    isMobile={isMobile}
                    blockchain={nft?.blockchain}
                  />
                </Box>
                <Box
                  className={styles.detailsContainerContentBox}
                  bgcolor={`${darkMode ? "#171C26" : "#FFF2F8"}`}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      component="h2"
                      color="secondary.main"
                      mb={4}
                      fontWeight={500}
                    >
                      {nft?.name}
                    </Typography>
                    <Divider className={styles.dividerBox} />
                    <Box className={styles.singleNftCardInfo}>
                      <Box sx={{ display: "flex", gap: 5 }}>
                        <Button
                          disableElevation
                          onClick={handleFixedToggler}
                          color="blue"
                          variant={
                            fixedButtonToggler ? "contained" : "outlined"
                          }
                        >
                          {t("FIXED_PRICE")}
                        </Button>
                        <Button
                          disableElevation
                          onClick={handleOpenToggler}
                          color="blue"
                          variant={openButtonToggler ? "contained" : "outlined"}
                        >
                          {t("OPEN_FOR_BID")}
                        </Button>
                      </Box>
                    </Box>
                    {fixedButtonToggler && (
                      <FixedComponent
                        darkMode={darkMode}
                        tokenInfo={nft}
                        handleStateChange={handleChange}
                      />
                    )}
                    {openButtonToggler && (
                      <OpenedComponent
                        isMobile={isMobile}
                        darkMode={darkMode}
                        tokenInfo={nft}
                        handleStateChange={handleChange}
                      />
                    )}
                    <Button
                      disableElevation
                      onClick={handleListing}
                      color="blue"
                      variant="outlined"
                    >
                      {t("COMPLETE_LISTING")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className={styles.detailsContainerMobile}>
                <NftImage
                  darkMode={darkMode}
                  isMobile={isMobile}
                  tokenURI={nft?.token_uri}
                  name={nft?.name}
                />
                <Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      component="h2"
                      color="secondary.main"
                      mb={2}
                    >
                      {nft?.name}
                    </Typography>
                    <Divider className={styles.dividerBox} />
                    <Box className={styles.singleNftCardInfo}>
                      <Box sx={{ display: "flex", gap: 5 }}>
                        <Button
                          disableElevation
                          onClick={handleFixedToggler}
                          color="blue"
                          variant={
                            fixedButtonToggler ? "contained" : "outlined"
                          }
                        >
                          {t("FIXED_PRICE")}
                        </Button>
                        <Button
                          disableElevation
                          onClick={handleOpenToggler}
                          color="blue"
                          variant={openButtonToggler ? "contained" : "outlined"}
                        >
                          {t("OPEN_FOR_BID")}
                        </Button>
                      </Box>
                    </Box>
                    {fixedButtonToggler && (
                      <FixedComponent
                        darkMode={darkMode}
                        handleStateChange={handleChange}
                        tokenInfo={nft}
                      />
                    )}
                    {openButtonToggler && (
                      <OpenedComponent
                        isMobile={isMobile}
                        darkMode={darkMode}
                        handleStateChange={handleChange}
                        tokenInfo={nft}
                      />
                    )}
                    <Button
                      disableElevation
                      onClick={handleListing}
                      color="blue"
                      variant="outlined"
                    >
                      {t("COMPLETE_LISTING")}
                    </Button>
                  </Box>
                  <NftResource
                    darkMode={darkMode}
                    tokenURI={nft?.token_uri}
                    contract={nft?.collection?.contract}
                    tokenId={nft?.token_id}
                    isMobile={isMobile}
                    blockchain={nft?.blockchain}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Typography>
      </Container>
    </>
  );
};

export default SellComponent;
