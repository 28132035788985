import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import {
    ETH_CURRENT_NET,
    BINANCE_CURRENT_NET,
    POLYGON_CURRENT_NET,
} from 'configs/constant'

export default function SwitchNetworkDialog({open, handleClose, onClickSwitchNetwork, blockchain, darkMode}) {
    const { t } = useTranslation();
    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('ASK_SWITCH_NETWORK')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('SWITCH_NETWORK_HEADER')}
                    {
                    blockchain === 'Binance'
                    ? BINANCE_CURRENT_NET.chainName
                    : blockchain === 'Polygon'
                    ? POLYGON_CURRENT_NET.chainName
                    : ETH_CURRENT_NET.chainName
                    }
                    {t('SWITCH_NETWORK_TAIL')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    sx={{
                    p: 1,
                        // backgroundColor: `${darkMode ? "#171C26" : "#FFF2F8"}`,
                    color: `${darkMode ? "#FFF2F8" : "#383838"}`,
                }}
                >{t('CANCEL')}</Button>
                <Button
                    onClick={onClickSwitchNetwork }
                    autoFocus
                    sx={{
                        p: 1,
                        // backgroundColor: `${darkMode ? "#171C26" : "#FFF2F8"}`,
                        color: `${darkMode ? "#FFF2F8" : "#383838"}`,
                    }}
                >{t('CONFIRM')}</Button>
            </DialogActions>
        </Dialog>
    );
}