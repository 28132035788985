import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { useCountDown } from "hooks/useCountDown";
import { useTranslation } from "react-i18next";
import { FacebookCircularProgress } from "./component/FacebookCircularProgress";
import { useSnackbar } from 'notistack';
import { add } from "date-fns";
import { TRANSACTION_EXPIRATION_TIME } from "configs/constant";


export const DepositExpiration = ({ darkMode, handleExpired }) => {
  const [futureDate, setFutureDate] = useState('');
  const [progress, setProgress] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, {variant});
  }
  const totalTimeSeconds = TRANSACTION_EXPIRATION_TIME?.minutes * 60 + TRANSACTION_EXPIRATION_TIME?.seconds;
  if (!futureDate) {
    const currentFutureDate = add(new Date(), {
        days: 0,
        hours: 0,
        minutes: TRANSACTION_EXPIRATION_TIME?.minutes,
        seconds: TRANSACTION_EXPIRATION_TIME?.seconds,
      });
      setFutureDate(currentFutureDate);
  }
  
  const { minutes, seconds } = useCountDown(!futureDate ? new Date() : futureDate );
  useEffect(() => {
    if (!!futureDate && !minutes && !seconds && handleExpired) {
        handleExpired();
        showSnackMessage(t('TRANSACTION_EXPIRED'), 'error');
    } else {
        const currentProgress = totalTimeSeconds - (minutes * 60 + seconds);
        setProgress(() => ((currentProgress * 100) / totalTimeSeconds));
    }
  }, [futureDate, handleExpired, minutes, seconds])
  return (
    // have to add isTimeup
    <Box sx={{
        maxWidth: "60px",
    }}>
        <FacebookCircularProgress progress={progress} darkMode={darkMode}/>
        <Typography sx={{
                color: `${!minutes && seconds < 30 ? "#ff4747" : "#31b26f"}`,
                fontSize: "20px",
                transition:"all ease-in",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}
            variant="body1"
            component="p"
        >
        {`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}</Typography>
    </Box>
  );
};