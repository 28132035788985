import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";

import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
import { useResetPasswordMutation } from "services/api.slice";

const ChangePasswordInterface = ({ darkMode }) => {
  const [showPassword, setShowPassword] = useState({
    showCurrentPass: false,
    showNewPass: false,
    showConfirmPass: false,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const [resetPasswordMutation, {isLoading}] = useResetPasswordMutation();

  const [passwords, setPasswords] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: ''
  });

  const handleChanged = (e) => {
    const {name, value} = e.target;

    setPasswords((prevState) => ({
      ...prevState,
      [name]: value
    }));

  }

  const handleSubmit = (e) => {
    resetPasswordMutation(passwords);
  }

  return (
    <>
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${
                darkMode ? "2px solid #ffffff" : "1px solid #171c26"
              }`,
            }}
          >
            {t("CHANGE_PASSWORD")}
          </Typography>
        </Box>
      )}
      {!isMobile ? (
        <Box
          sx={{
            color: `${darkMode ? "#ffffff" : "#040404"}`,
            backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              borderRadius: "10px",
            }}
          >
            <Box sx={{ px: 5, pt: 1.7 }} component="form">
              {/* Current Password */}
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <label
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  htmlFor="sellerName"
                >
                  {t("CURRENT_PASSWORD")}
                </label>
                <OutlinedInput
                  type={showPassword?.showCurrentPass ? "text" : "password"}
                  placeholder={t("ENTER_YOUR_CURRENT_PASSWORD")}
                  name="currentPassword"
                  required
                  style={{
                    fontSize: "14px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "6px",
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    width: "90%",
                  }}
                  onChange={handleChanged}
                  classes={{
                    input: `${darkMode ? "inputField" : "inputFieldLight"}`,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword({
                            showCurrentPass: !showPassword?.showCurrentPass,
                          })
                        }
                      >
                        {showPassword?.showCurrentPass ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Stack>
              {/* New password */}
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <label
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  htmlFor="sellerName"
                >
                  {t("NEW_PASSWORD")}
                </label>
                <OutlinedInput
                  className={darkMode ? "inputField" : undefined}
                  type={showPassword?.showNewPass ? "text" : "password"}
                  placeholder={t("ENTER_NEW_PASSWORD")}
                  name="password"
                  required
                  style={{
                    fontSize: "14px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "6px",
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    width: "90%",
                  }}
                  onChange={handleChanged}
                  classes={{
                    input: `${darkMode ? "inputField" : "inputFieldLight"}`,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword({
                            showNewPass: !showPassword?.showNewPass,
                          })
                        }
                      >
                        {showPassword?.showNewPass ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Stack>
              {/* Confirm password */}
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <label
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  htmlFor="sellerName"
                >
                  {t("CONFIRM_PASSWORD")}
                </label>
                <OutlinedInput
                  className={darkMode ? "inputField" : undefined}
                  type={showPassword?.showConfirmPass ? "text" : "password"}
                  placeholder={t("ENTER_NEW_PASSWORD")}
                  name="confirmPassword"
                  required
                  style={{
                    fontSize: "14px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "6px",
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    width: "90%",
                  }}
                  onChange={handleChanged}
                  classes={{
                    input: `${darkMode ? "inputField" : "inputFieldLight"}`,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword({
                            showConfirmPass: !showPassword?.showConfirmPass,
                          })
                        }
                      >
                        {showPassword?.showConfirmPass ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Stack>
              {/* Submit */}
              <Stack
                mt={4}
                width={"90%"}
                direction="row"
                justifyContent="flex-end"
              >
                <GradientButtonPrimary
                  sx={{ py: 1.5, px: 5 }}
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                >
                  {t("SAVE")}
                </GradientButtonPrimary>
              </Stack>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            color: `${darkMode ? "#ffffff" : "#040404"}`,
            backgroundColor: `${darkMode ? "#121212" : "#fff2f8"}`,
            borderRadius: "10px",
            height: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              p: 1,
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
              borderRadius: "10px",
            }}
          >
            <Box component="form">
              {/* Current Password */}
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <label
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  htmlFor="sellerName"
                >
                  {t("CURRENT_PASSWORD")}
                </label>
                <OutlinedInput
                  className={darkMode ? "inputField" : undefined}
                  type={showPassword?.showCurrentPass ? "text" : "password"}
                  placeholder={t("ENTER_YOUR_CURRENT_PASSWORD")}
                  name="currentPassword"
                  required
                  style={{
                    fontSize: "14px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "6px",
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                  }}
                  onChange={handleChanged}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword({
                            showCurrentPass: !showPassword?.showCurrentPass,
                          })
                        }
                      >
                        {showPassword?.showCurrentPass ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Stack>
              {/* New password */}
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <label
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  htmlFor="sellerName"
                >
                  {t("NEW_PASSWORD")}
                </label>
                <OutlinedInput
                  className={darkMode ? "inputField" : undefined}
                  type={showPassword?.showNewPass ? "text" : "password"}
                  placeholder={t("ENTER_NEW_PASSWORD")}
                  name="password"
                  required
                  style={{
                    fontSize: "14px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "6px",
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                  }}
                  onChange={handleChanged}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword({
                            showNewPass: !showPassword?.showNewPass,
                          })
                        }
                      >
                        {showPassword?.showNewPass ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Stack>
              {/* Confirm password */}
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <label
                  style={{
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  htmlFor="sellerName"
                >
                  {t("CONFIRM_PASSWORD")}
                </label>
                <OutlinedInput
                  className={darkMode ? "inputField" : undefined}
                  type={showPassword?.showConfirmPass ? "text" : "password"}
                  placeholder={t("ENTER_NEW_PASSWORD")}
                  name="confirmPassword"
                  required
                  style={{
                    fontSize: "14px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "6px",
                    color: `${darkMode ? "#ffffff" : "#040404"}`,
                    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                  }}
                  onChange={handleChanged}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword({
                            showConfirmPass: !showPassword?.showConfirmPass,
                          })
                        }
                      >
                        {showPassword?.showConfirmPass ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Stack>
              {/* Submit */}
              <Stack mt={4} direction="row" justifyContent="flex-end">
                <GradientButtonPrimary
                  sx={{ py: 1.5, px: 5 }}
                  startIcon={<SaveIcon />}
                >
                  {t("SAVE")}
                </GradientButtonPrimary>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ChangePasswordInterface;
