import React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { AiFillCaretDown } from "react-icons/ai";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

import { useStyles } from "components/Alert/style";

const ListingDialog = ({
  open,
  handleClose,
  darkMode,
  price,
  blockchain,
  currentStep,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: 30000,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"></DialogTitle>
      <DialogContent>
        <Accordion
          defaultExpanded
          disableGutters
          sx={{
            backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
            color: `${darkMode ? "#ffffff" : "#121212"}`,
          }}
          expanded={currentStep === 1}
        >
          <AccordionSummary
            sx={{
              backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
              color: `${darkMode ? "#ffffff" : "#121212"}`,
            }}
            expandIcon={
              <Typography component="span" color="secondary">
                <AiFillCaretDown />
              </Typography>
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {currentStep > 1 ? (
              <CheckCircleIcon className={classes.icon1} />
            ) : (
              <span style={{ margin: "5px 10px 0px 0px", fontSize: 12 }}>
                1
              </span>
            )}
            <Typography variant="subtitle1">
              {t("INITIALIZE_WALLET")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
                color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                opacity: 0.9,
                lineHeight: 1.8,
                textAlign: "justify",
                pr: 2,
              }}
            >
              {t("INITIALIZE_WALLET_DESC")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          sx={{
            backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
            color: `${darkMode ? "#ffffff" : "#121212"}`,
          }}
          expanded={currentStep === 2}
        >
          <AccordionSummary
            expandIcon={
              <Typography component="span" color="secondary">
                <AiFillCaretDown />
              </Typography>
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            {currentStep > 2 ? (
              <CheckCircleIcon className={classes.icon1} />
            ) : (
              <span style={{ margin: "5px 10px 0px 0px", fontSize: 12 }}>
                2
              </span>
            )}
            <Typography variant="subtitle1">{t("APPROVE_ITEM")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
                color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                opacity: 0.9,
                lineHeight: 1.8,
                textAlign: "justify",
                pr: 2,
              }}
            >
              {t("APPROVE_ITEM_DESC")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          sx={{
            backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
            color: `${darkMode ? "#ffffff" : "#121212"}`,
          }}
          expanded={currentStep === 3}
        >
          <AccordionSummary
            expandIcon={
              <Typography component="span" color="secondary">
                <AiFillCaretDown />
              </Typography>
            }
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            {currentStep === 3 ? (
              <>
                <CircularProgress size={20} className={classes.icon2} />
                <span style={{ margin: "0px 10px 0px -18px", fontSize: 12 }}>
                  3
                </span>
              </>
            ) : currentStep > 3 ? (
              <CheckCircleIcon className={classes.icon1} />
            ) : (
              <span style={{ margin: "5px 10px 0px 0px", fontSize: 12 }}>
                3
              </span>
            )}
            <Typography variant="subtitle1">
              {t("DEPLOY_COLLECTION")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
                color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                opacity: 0.9,
                lineHeight: 1.8,
                textAlign: "justify",
                pr: 2,
              }}
            >
              {t("DEPLOY_COLLECTION_DESC")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          sx={{
            backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
            color: `${darkMode ? "#ffffff" : "#121212"}`,
          }}
          expanded={currentStep === 4}
        >
          <AccordionSummary
            expandIcon={
              <Typography component="span" color="secondary">
                <AiFillCaretDown />
              </Typography>
            }
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography variant="subtitle1" component="div">
              {currentStep === 4 ? (
                <>
                  <CircularProgress size={20} className={classes.icon2} />
                  <span style={{ margin: "0px 10px 0px -18px", fontSize: 12 }}>
                    4
                  </span>
                </>
              ) : currentStep > 4 ? (
                <CheckCircleIcon className={classes.icon1} />
              ) : (
                <span style={{ margin: "5px 10px 0px 0px", fontSize: 12 }}>
                  4
                </span>
              )}
              {`${t("CONFIRM")} ${price}`}
              {blockchain === "Binance"
                ? "BNB"
                : blockchain === "Polygon"
                ? "MATIC"
                : "ETHE"}
              {t("LISTING")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
                color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                opacity: 0.9,
                lineHeight: 1.8,
                textAlign: "justify",
                pr: 2,
              }}
            >
              {t("LISTING_DESC")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          sx={{
            backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
            color: `${darkMode ? "#ffffff" : "#121212"}`,
          }}
          expanded={currentStep === 5}
        >
          <AccordionSummary
            expandIcon={
              <Typography component="span" color="secondary">
                <AiFillCaretDown />
              </Typography>
            }
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            {currentStep === 5 ? (
              <>
                <CircularProgress size={20} className={classes.icon2} />
                <span style={{ margin: "0px 10px 0px -18px", fontSize: 12 }}>
                  5
                </span>
              </>
            ) : currentStep === 5 ? (
              <CheckCircleIcon className={classes.icon1} />
            ) : (
              <span style={{ margin: "5px 10px 0px 0px", fontSize: 12 }}>
                5
              </span>
            )}
            <Typography variant="subtitle1">
              {t("APPROVE_COLLECTION")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#fff2f8"}`,
                color: `${darkMode ? "#c4c4c4" : "GrayText"}`,
                opacity: 0.9,
                lineHeight: 1.8,
                textAlign: "justify",
                pr: 2,
              }}
            >
              {t("APPROVE_COLLECTION_DESC")}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
    </Dialog>
  );
};
export default ListingDialog;
