import React, { useEffect, useState } from 'react';
import SelectComponent from "./components/SelectComponent";
import { useApolloQuery } from 'hooks/useApolloQuery';
import { useMount } from 'hooks/useMount';
const CollectionSelect = ({ darkMode, name, handleChange, defaultValue, isRequired }) => {
  const attrData = {
    name: name,
    label: "COLLECTIONS",
    isRequired: isRequired,
    helperText: "COLLECTIONS_SELECTOR_PLACEHOLDER",
    color: "secondary",
    handleChange: handleChange
  };

  const [collections, setCollections] = useState([]);
  const { data: collectionData, error, refetch } = useApolloQuery('collections', {
    name: ''
  });

  useMount(refetch);
  useEffect(() => {
    if (collectionData)
      setCollections(collectionData?.collectionsSearch);
  }, [collectionData]);

  return (
    <SelectComponent attr = {attrData} data = {collections} defaultValue={defaultValue}/>
  );
};

export default CollectionSelect;