import React, { useState } from "react";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";
import styles from "components/NftCard/NftCard.module.css";

export const NftCardBackground = ({
    darkMode,
    tokenURI,
    name,
}) => {
    const [loading, setLoading] = useState(true);

    return (
        <Box>
            <img
                className={styles.imageStyle}
                src={tokenURI}
                onLoad={() => setLoading(false)}
                alt={name}
                hidden={loading}
            />
            <>
                {loading ? (
                <Skeleton
                    sx={{
                        backgroundColor: `${
                            darkMode ? "#171C26" : "#fff2f8"
                        }`,
                    }}
                    animation="wave"
                    variant="rectangular"
                    style={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "10px",
                    }}
                />
                ) : null}
            </>
            </Box>
    );
}