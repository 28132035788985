import { Box } from "@mui/system";
import {  useState } from 'react';
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ClickAwayListener } from "@mui/material";
import { useCopyToClipboard } from 'usehooks-ts'
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";

export const CopyNftInfoDisplay = ({ children, darkMode, label, copyValue }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [value, copy] = useCopyToClipboard();

    const handleTooltipClose = () => {
        setOpen(false);
    }

    const handleTooltipOpen = () => {
        setOpen(true);
    }
    return (
        <Box className={styles.nftDisplayDetailsContent} sx={{ zIndex: 50 }}>
            <Typography
                variant="subtitle1"
                gutterBottom
                color="gray"
            >
                {t(label)}
            </Typography>
            <div
                style={{
                display: "flex",
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    color={darkMode ? "#FFFFFF" : "#121212"}
                    mb={2}
                    fontWeight={500}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    {children}
                </Typography>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        placement="top"
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={t("TOOLTIP_COPIED")}
                    >
                        <Typography
                            component="span"
                            onClick = {() => {
                                copy(copyValue);
                                handleTooltipOpen();
                            }}
                            color={darkMode ? "#FFFFFF" : "#121212"}
                            mb={2}
                            ml={1}
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            <ContentCopyIcon/>
                        </Typography>
                    </Tooltip>
                </ClickAwayListener>
            </div>
        </Box>
    );
}