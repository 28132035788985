import {
  Avatar,
  Button,
  IconButton,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
// Icons
import { FiSave } from "react-icons/fi";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { IoCameraSharp } from "react-icons/io5";
import LandscapeIcon from "@mui/icons-material/Landscape";
// React router dom
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { useTheme } from "@emotion/react";
import { RiCameraOffFill } from "react-icons/ri";
import { Cancel } from "@mui/icons-material";
// Gradient Button
import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
import Loader from "components/Loader/Loader";
import { useGetUserDetailsQuery, useUpdateUserProfileMutation } from "services/api.slice";
import { PINATA_ASSET_BASE_URL } from 'configs/constant';
import { pinFileToIPFS } from 'Utils';
import "components/ProfileInterface/EditProfile.css";
// Avatar
import UserAvatar from "assets/userProfileAvatar.png";
import { defaultPngAvatar } from "configs/constant";
import { useCheckedLogin } from "hooks/useCheckedLogin";

const Input = styled("input")({
  display: "none",
});

const EditProfile = ({ darkMode }) => {
  const [updateUserProfileMutation, { isLoad } ] = useUpdateUserProfileMutation();
  const {data: user} = useGetUserDetailsQuery('userDetails');
  // const user = useSelector((state) => state.auth.user);

  var userInfo = {};
  const [userAvatar, setUserAvatar] = React.useState();
  const [userLogo, setUserLogo] = React.useState();
  const [userCover, setUserCover] = React.useState();
  const [userBanner, setUserBanner] = React.useState();

  const [mailError, setMailError] = React.useState("");
  const [avatarError, setAvatarError] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [isPinning, setIsPinning] = useState(false);
  useCheckedLogin();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (user) {
      setUserAvatar(user?.image);
      setUserCover(user?.cover);
    }
  }, [user]);
  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, {variant});
  }

  const isMail = (userEmail) => {
    return /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
      userEmail
    );
  };

  const handleImageUpload = (e) => {
    setUserAvatar(URL.createObjectURL(e.target.files[0]));
    setUserLogo(e.target.files[0]);
  };

  const handleCoverUpload = (e) => {
    setUserCover(URL.createObjectURL(e.target.files[0]));
    setUserBanner(e.target.files[0]);
  };

  useEffect(() => {
    setIsLoading(true);
    setLoaderMessage("Loading...");
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleSubmitEditProfile = async (e) => {
    e.preventDefault();
    try {
      if (userInfo?.email && !isMail(userInfo.email)) {
        setMailError("Looks like it is not an email");
        return;
      } else {
        setMailError("");
      }

      if (!user?.image && !userAvatar) {
        setAvatarError("Please upload a profile photo of yours!");
        return;
      } else {
        setAvatarError("");
      }
      setIsPinning(true);

      if (userLogo) {
        const logo = await pinFileToIPFS(userLogo);
        userInfo = {
          ...userInfo,
          image: logo,
        }
      }

      if (userBanner) {
        const userCover = await pinFileToIPFS(userBanner);
        userInfo = {
          ...userInfo,
          cover: userCover,
        }
      }

      if (userInfo?.image) {
        userInfo.image = `${PINATA_ASSET_BASE_URL}/${userInfo?.image}`;
      }
      if (userInfo?.cover) {
        userInfo.cover = `${PINATA_ASSET_BASE_URL}/${userInfo?.cover}`;
      }
      updateUserProfileMutation(userInfo);
      setIsPinning(false);
      showSnackMessage(t('MSG_SAVE_ASSET_SUCCESS'), 'success');
      navigate("/profile/user-profile");
    } catch(e) {
      console.log(e);
      showSnackMessage(t('MSG_FAILED'), 'error');
    }

  };

  const handleInputChanged = (e) => {
    const { name, value } = e.target;
    userInfo = {
      ...userInfo,
      [name]: value
    }
  }

  return (
    <>
      <Loader
        isLoading={isLoading}
        message={loaderMessage}
        darkMode={darkMode}
      />
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${
                darkMode ? "2px solid #ffffff" : "1px solid #171c26"
              }`,
            }}
          >
            {t("SETTINGS_EDIT_PROFILE")}
          </Typography>
        </Box>
      )}
      {!isMobile ? (
        <Box
          sx={{
            color: `${darkMode ? "#ffffff" : "#040404"}`,
            postion: "relative",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,

              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              {userCover ? (
                <>
                  <img
                    height={"200px"}
                    width={"100%"}
                    style={{ opacity: "0.9", filter: "brightness(0.9)" }}
                    src={userCover}
                    alt="Cover"
                  />
                  <Box position="absolute" sx={{ top: 0, right: 0 }}>
                    <IconButton onClick={() => setUserCover(null)}>
                      <Cancel />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <React.Fragment>
                  <Tooltip title="Click to upload cover">
                    <IconButton>
                      <label htmlFor="icon-button-file-upload">
                        <Input
                          accept="image/*"
                          id="icon-button-file-upload"
                          type="file"
                          onChange={handleCoverUpload}
                        />
                        <LandscapeIcon
                          sx={{
                            height: 150,
                            width: 150,
                            color: "rgba(85, 85, 85, 0.7)",
                          }}
                        />
                      </label>
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )}
            </Stack>
            <Box
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                p: 5,
                pt: 0,
                borderRadius: "10px",
              }}
            >
              <Box sx={{ textAlign: "center", position: "relative", mt: -5 }}>
                {!userAvatar ? (
                  <Avatar
                    sx={{
                      width: "100px",
                      height: "100px",
                      border: `2px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                    src={user?.image ? user.image : defaultPngAvatar}
                    alt="User Name"
                  />
                ) : (
                  <Avatar
                    src={userAvatar}
                    sx={{
                      width: "100px",
                      height: "100px",
                      border: `2px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                  />
                )}
                {!userAvatar ? (
                  <Box
                    sx={{ position: "absolute", left: "5.2%", bottom: "-24%" }}
                  >
                    <Box>
                      <label htmlFor="icon-button-file-upload">
                        <Input
                          accept="image/*"
                          id="icon-button-file-upload"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        <IconButton
                          aria-label="upload picture"
                          component="span"
                        >
                          <Typography
                            component="span"
                            color="secondary"
                            fontSize={25}
                          >
                            <IoCameraSharp />
                          </Typography>
                        </IconButton>
                      </label>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{ position: "absolute", left: "5.2%", bottom: "-24%" }}
                  >
                    <IconButton onClick={() => setUserAvatar(null)}>
                      <Typography
                        component="span"
                        color="secondary"
                        fontSize={25}
                      >
                        <RiCameraOffFill />
                      </Typography>
                    </IconButton>
                  </Box>
                )}
              </Box>
              {avatarError && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ color: "red", mt: 2 }}
                >
                  {avatarError}
                </Typography>
              )}
              <Box
                onSubmit={handleSubmitEditProfile}
                sx={{ mt: 5 }}
                component="form"
              >
                {/* Full Name */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    className={darkMode && "inputField"}
                    type="text"
                    placeholder={t("EDIT_PROFILE_ENTER_FULL_NAME_HERE")}
                    name="full_name"
                    required
                    color="secondary"
                    style={{
                      fontSize: "14px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    }}
                    label={t("FULL_NAME")}
                    onChange={ handleInputChanged }
                    defaultValue={user?.full_name}
                  />
                </Stack>
                {/* User Name */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    className={darkMode && "inputField"}
                    type="text"
                    placeholder={t("EDIT_PROFILE_ENTER_YOUR_USER_NAME_HERE")}
                    name="username"
                    required
                    style={{
                      fontSize: "14px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    }}
                    color="secondary"
                    label={t("USER_NAME")}
                    onChange={ handleInputChanged }
                    defaultValue={user?.username}
                    disabled
                  />
                </Stack>
                {/* Email */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    className={darkMode && "inputField"}
                    type="email"
                    placeholder={t("EDIT_PROFILE_ENTER_EMAIL_HERE")}
                    name="email"
                    // onChange={(e) => setUserMail(e.target.value)}
                    style={{
                      fontSize: "14px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    }}
                    color="secondary"
                    label={t("EMAIL")}
                    onChange={ handleInputChanged }
                    defaultValue={user?.email}
                    required
                    disabled
                  />
                  {mailError && (
                    <Typography
                      variant="caption"
                      component="p"
                      sx={{ color: "red" }}
                    >
                      {mailError}
                    </Typography>
                  )}
                </Stack>
                {/* Bio */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextareaAutosize
                    placeholder="Enter your bio"
                    name="bio"
                    required
                    rows={5}
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "14px",
                      minHeight: "100px",
                      color: `${darkMode ? "#ffffff" : "#121212"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                      resize: "vertical",
                      zIndex: 1000,
                    }}
                    label="Bio *"
                    onChange={ handleInputChanged }
                    defaultValue={user?.bio}
                    color="secondary"
                  />
                </Stack>
                {/* Mobile Number */}
                {/* <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    className={darkMode && "inputField"}
                    placeholder={t("EDIT_PROFILE_ENTER_MOBILE_NUMBER_HERE")}
                    name="phone"
                    required
                    style={{
                      fontSize: "14px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                    }}
                    label={t("MOBILE_NUMBER")}
                    onChange={ handleInputChanged }
                    defaultValue={user?.phone}
                    color="secondary"
                  />
                </Stack> */}
                <Box
                  sx={{
                    mt: 8,
                    mb: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 3,
                    mr: 1,
                  }}
                >
                  <Button
                    onClick={() => navigate("/profile/user-profile")}
                    variant="outlined"
                    color="pink"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                      padding: "8px 1.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography component="span" color="secondary" mt={1}>
                      <AiOutlineCloseSquare />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        color: `${darkMode ? "#ffffff" : "#040404"}`,
                      }}
                    >
                      {t("CLOSE")}
                    </Typography>
                  </Button>
                  <GradientButtonPrimary
                    // onclick={() => handleSave}
                    type="submit"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <Typography component="span" color="#ffffff" mt={1}>
                      <FiSave />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        color: "#ffffff",
                      }}
                    >
                      {t("SAVE")}
                    </Typography>
                  </GradientButtonPrimary>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            color: `${darkMode ? "#ffffff" : "#040404"}`,
            postion: "relative",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,

              borderRadius: "10px",
              position: "relative",
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              {userCover ? (
                <>
                  <img
                    src={userCover}
                    alt="Cover"
                    style={{
                      display: "block",
                      height: "100px",
                      width: "100%",
                    }}
                  />
                  <Box position="absolute" sx={{ top: 0, right: 0 }}>
                    <IconButton onClick={() => setUserCover(null)}>
                      <Cancel />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <React.Fragment>
                  <Tooltip title="Click to upload cover">
                    <IconButton>
                      <label htmlFor="icon-button-file-upload">
                        <Input
                          accept="image/*"
                          id="icon-button-file-upload"
                          type="file"
                          onChange={handleCoverUpload}
                        />
                        <LandscapeIcon
                          sx={{
                            height: 100,
                            width: 100,
                            color: "rgba(85, 85, 85, 0.7)",
                          }}
                        />
                      </label>
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )}
            </Stack>
            <Box
              sx={{
                backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
                p: 1,
                pt: 0,
                borderRadius: "10px",
              }}
            >
              <Box sx={{ textAlign: "center", position: "relative", mt: -5 }}>
                {!userAvatar ? (
                  <Avatar
                    sx={{
                      width: "80px",
                      height: "80px",
                      border: `2px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                    src={UserAvatar}
                    alt="User Name"
                  />
                ) : (
                  <Avatar
                    src={userAvatar}
                    sx={{
                      width: "80px",
                      height: "80px",
                      border: `2px solid ${darkMode ? "#ffffff" : "#01D4FA"}`,
                    }}
                  />
                )}
                {!userAvatar ? (
                  <Box
                    sx={{ position: "absolute", left: "7%", bottom: "-24%" }}
                  >
                    <Box>
                      <label htmlFor="icon-button-file-upload">
                        <Input
                          accept="image/*"
                          id="icon-button-file-upload"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        <IconButton
                          aria-label="upload picture"
                          component="span"
                        >
                          <Typography
                            component="span"
                            color="secondary"
                            fontSize={25}
                          >
                            <IoCameraSharp />
                          </Typography>
                        </IconButton>
                      </label>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{ position: "absolute", left: "7%", bottom: "-24%" }}
                  >
                    <IconButton onClick={() => setUserAvatar(null)}>
                      <Typography
                        component="span"
                        color="secondary"
                        fontSize={25}
                      >
                        <RiCameraOffFill />
                      </Typography>
                    </IconButton>
                  </Box>
                )}
              </Box>
              {avatarError && (
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ color: "red", mt: 2 }}
                >
                  {avatarError}
                </Typography>
              )}
              <Box
                onSubmit={handleSubmitEditProfile}
                sx={{ mt: 5 }}
                component="form"
              >
                {/* Full Name */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    className={darkMode && "inputField"}
                    type="text"
                    placeholder={t("EDIT_PROFILE_ENTER_FULL_NAME_HERE")}
                    name="userFullName"
                    required
                    style={{
                      fontSize: "14px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                    }}
                    label={t("FULL_NAME")}
                    color="secondary"
                  />
                </Stack>
                {/* User Name */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    className={darkMode && "inputField"}
                    type="text"
                    placeholder={t("EDIT_PROFILE_ENTER_YOUR_USER_NAME_HERE")}
                    name="userName"
                    required
                    style={{
                      fontSize: "14px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                    }}
                    label= {t("USER_NAME")}
                    color="secondary"
                    disabled
                  />
                </Stack>
                {/* Email */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    className={darkMode && "inputField"}
                    type="email"
                    placeholder={t("EDIT_PROFILE_ENTER_EMAIL_HERE")}
                    name="userEmail"
                    // onChange={(e) => setUserMail(e.target.value)}
                    style={{
                      fontSize: "14px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                    }}
                    required
                    label={t("EMAIL")}
                    color="secondary"
                    disabled
                  />
                  {mailError && (
                    <Typography
                      variant="caption"
                      component="p"
                      sx={{ color: "red" }}
                    >
                      {mailError}
                    </Typography>
                  )}
                </Stack>
                {/* Bio */}
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextareaAutosize
                    placeholder="Enter your bio"
                    name="userBio"
                    required
                    rows={5}
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "14px",
                      minHeight: "50px",
                      color: `${darkMode ? "#ffffff" : "#121212"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                      resize: "vertical",
                      zIndex: 1000,
                    }}
                    label="Bio"
                    color="secondary"
                  />
                </Stack>
                {/* Mobile Number */}
                {/* <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    className={darkMode && "inputField"}
                    type="number"
                    placeholder={t("EDIT_PROFILE_ENTER_MOBILE_NUMBER_HERE")}
                    name="userMobileNumber"
                    required
                    style={{
                      fontSize: "14px",
                      color: `${darkMode ? "#ffffff" : "#040404"}`,
                      backgroundColor: `${darkMode ? "#121212" : "#ffffff"}`,
                    }}
                    label={t("MOBILE_NUMBER")}
                    color="secondary"
                  />
                </Stack> */}
                <Box
                  sx={{
                    mt: 4,
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 2,
                    // width: "90%",
                  }}
                >
                  <Button
                    onClick={() => navigate("/profile/user-profile")}
                    variant="outlined"
                    color="pink"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <Typography component="span" color="secondary" mt={1}>
                      <AiOutlineCloseSquare />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        color: `${darkMode ? "#ffffff" : "#040404"}`,
                      }}
                    >
                      {t("CLOSE")}
                    </Typography>
                  </Button>
                  <Button
                      // onClick={() => handleSave}
                    color="pink"
                    type="submit"
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <Typography component="span" color="#ffffff" mt={1}>
                      <FiSave />
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        textTransform: "capitalize",
                        color: "#ffffff",
                      }}
                    >
                      {t("SAVE")}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default EditProfile;
