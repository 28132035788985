export const fontSizes = {
  font8: "8px",
  font10: "10px",
  font12: "12px",
  font14: "14px",
  font15: "15px",
  font16: "16px",
  font18: "18px",
  font22: "22px",
  font20: "20px",
  font24: "24px",
  font26: "26px",
  font29: "29px",
  font30: "30px",
  font32: "32px",
  font40: "40px",
  font42: "42px",
  font45: "45px",
  font60: "60px",
  font72: "72px",
};

export const fontWeights = {
  weight400: 400,
  weight500: 500,
  weight600: 600,
  weight700: 700,
};
