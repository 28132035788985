import {
    IconButton,
    Typography
  } from "@mui/material";

import { Box } from "@mui/system";
import { useState } from "react";
import { styled } from "@mui/material/styles";
// Icons
import { TiTimes } from "react-icons/ti";
import { ImImage } from "react-icons/im";

const ImageComponent = ({defaultImage, darkMode, attr, setUploadImage}) => {
  const {name} = attr;
  const [image, setImage] = useState(defaultImage);
  const Input = styled("input")({
    display: "none",
  });

  const handleImageUpload = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setUploadImage(e.target.files[0]);
  };
  
  return (
    <Box>
      {!image ? (
          <Box
            sx={{
                my: 2,
                width: "60%",
                height: "150px",
                border: "1px solid #c4c4c4",
                borderStyle: "dashed",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                p: 3,
            }}
          >
          <label htmlFor="icon-button-file-front">
            <Input
              accept="image/*"
              id="icon-button-file-front"
              type="file"
              onChange={handleImageUpload}
              name={name}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <Typography
                  component="span"
                  color="secondary"
                  fontSize={30}
              >
                  <ImImage />
              </Typography>
            </IconButton>
          </label>
        </Box>
      ) : (
        <Box sx={{ my: 2, position: "relative" }}>
          <Box
              sx={{
              position: "absolute",
              left: "1%",
              top: "4%",
              }}
          >
              <IconButton
                sx={{
                  backgroundColor: `${
                  darkMode ? "#fff2f8" : "#171c26"
                }`,
              }}
              onClick={() => {setImage(null); setUploadImage(null);}}
              >
              <TiTimes
                fontSize={"1rem"}
                color={darkMode ? "#171c26" : "#ffffff"}
              />
              </IconButton>
          </Box>
          <Box
            sx={{
            border: "1px solid #c4c4c4",
            borderStyle: "dashed",
            padding: "5px",
            width: "315px",
            height: "200px",
            }}
          >
            <img
              style={{ height: "200px", width: "315px" }}
              src={image}
              alt="Uploaded"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ImageComponent;