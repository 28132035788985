import { configureStore } from '@reduxjs/toolkit'
import authReducer from "slices/auth.slice";
import nftReducer from "slices/graphql.slice";
import { apiSlice } from 'services/api.slice';
import messageReducer from "slices/message";
const reducer = {
  auth: authReducer,
  nft: nftReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  message: messageReducer,
}

export const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});
