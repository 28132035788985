import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers';

export default function InputDateRangePicker({handleChange, defaultF, defaultT}) {
  const handleOnChange = (e, context) => {
    const [array1, array2] = e;

    handleChange('auction_start_at', ((new Date(array1?.$y, array1?.$M, array1?.$D)).getTime()) / 1000);
    handleChange('auction_end_at', ((new Date(array2?.$y, array2?.$M, array2?.$D)).getTime()) / 1000);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['SingleInputDateRangeField']}>
        <DateRangePicker
          slots={{ field: SingleInputDateRangeField }}
          onChange={ handleOnChange }
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export function SingleDatePicker({label, name, isMobile, handleChange}) {
  const handleDateChange = (e) => {
    handleChange('startTime', ((new Date(e?.$y, e?.$M, e?.$D)).getTime()) / 1000);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        {!isMobile && (<DatePicker label={label} name={name} onChange={handleDateChange} color="secondary"/>)}
        {isMobile && (<MobileDatePicker label={label} name={name} onChange={handleDateChange} color="secondary"/>)}
      </DemoContainer>
    </LocalizationProvider>
  );
}
