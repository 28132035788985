import React, { useState, useEffect } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Icons
import { IoPersonSharp, IoLanguage } from "react-icons/io5";
import { IoIosSwitch } from "react-icons/io";

const ProfileSideBar = ({ darkMode }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const profileSideMenu = [
    {
      id: 1,
      name: "USER_INFOMATION",
      icon: <IoPersonSharp />,
      path: "/user-profile",
    },
    {
      id: 2,
      name: "SETTINGS_LANGUAGE",
      icon: <IoLanguage />,
      path: "/language",
    },
    {
      id: 3,
      name: "SETTINGS_THEME",
      icon: <IoIosSwitch />,
      path: "/theme",
    },
  ];

  return (
    <Box
      sx={{
        color: `${darkMode ? "#ffffff" : "#040404"}`,
        backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
        borderRadius: "10px",
      }}
    >
      <List sx={{ display: "flex", flexDirection: "column", py: 2 }}>
        {profileSideMenu.map((psm) => (
          <Box key={psm.id}>
            <ListItem
              disablePadding
              sx={{ pl: 0.5, py: 1.5, position: "relative" }}
            >
              <ListItemButton
                sx={
                  location.pathname === `/profile${psm.path}`
                    ? {
                        mr: 3,
                        borderRadius: "4px",
                        background:
                          "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)",
                      }
                    : null
                }
                onClick={() => navigate(`/profile${psm.path}`)}
              >
                <ListItemIcon>
                  {location.pathname === `/profile${psm.path}` ? (
                    <>
                      {psm.icon ? (
                        <Typography
                          component="span"
                          color="#ffffff"
                          fontSize={22}
                        >
                          {psm.icon}
                        </Typography>
                      ) : (
                        <img
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                          src={psm.iconMask}
                          alt="..."
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {psm.icon ? (
                        <Typography
                          component="span"
                          color="secondary"
                          fontSize={22}
                        >
                          {psm.icon}
                        </Typography>
                      ) : (
                        <React.Fragment>
                          {darkMode ? (
                            <img
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                              src={psm.iconMask}
                              alt="..."
                            />
                          ) : (
                            <img
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                              src={psm.iconMaskLight}
                              alt="..."
                            />
                          )}
                        </React.Fragment>
                      )}
                    </>
                  )}
                </ListItemIcon>
                {location.pathname === `/profile${psm.path}` ? (
                  <Typography
                    variant="body2"
                    component="span"
                    ml={-1}
                    fontWeight={500}
                    color="#ffffff"
                  >
                    {t(`${psm.name}`)}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    component="span"
                    ml={-1}
                    fontWeight={500}
                    color="secondary"
                  >
                    {t(`${psm.name}`)}
                  </Typography>
                )}
              </ListItemButton>
            </ListItem>
            <Divider
              sx={
                psm.id === profileSideMenu.length
                  ? { backgroundColor: "gray", opacity: "0" }
                  : { backgroundColor: "gray", opacity: "0.2" }
              }
            />
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default ProfileSideBar;
